import ClientList from "./ClientList";
import AddClient from "./AddClient";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../MainContext";
import EditClient from "./EditClient";
import OwnerList from "./OwnerList";


const ClientForm = () => {
  const [edit, setEdit] = useState()
  const [nextToOwners, setNextToOwners] = useState(false)

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {edit ? 
        <EditClient client_id={edit} setEdit={setEdit} />
        :
        <AddClient />
      }
      {!nextToOwners ? 
        <ClientList setEdit={setEdit} setNextToOwners={setNextToOwners}/>
        :
        <OwnerList setNextToOwners={setNextToOwners} />
      }
    </div>
  );
};

export default ClientForm;
