import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { texts } from "./texts";
import { request } from "./request";

export const MainContext = createContext();
const isValidJSON = (json) => {
    try {
        if (!json) return false;
        JSON.parse(json);
        return true;
    } catch {
        return false;
    }
};

export const MainContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [properties, setProperties] = useState([]);
    const [clients, setClients] = useState([]);
    const [employees, setEmployee] = useState([]);
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [keys, setKeys] = useState([]);
    const [newCode, setNewCode] = useState();
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [preLocationValues, setPreLocationValues] = useState({});
    const [language, setLanguage] = useState("arm");
    const [errorArr, setErrorArr] = useState([])
    const [users, setUsers] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [codes, setCodes] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [permissions, setPermissions] = useState(isValidJSON(window.localStorage.getItem("premissions")) ? JSON.parse(window.localStorage.getItem("premissions")) : {})

    const switchLanguage = (lang) => {
        setLanguage(lang);
    };


    const flattenTexts = () => {
        const newObj = { ...JSON.parse(JSON.stringify(texts)) };
        Object.entries(texts).map(([key, value]) => {
            console.log(key);
            Object.entries(value).map(([key1, value1]) => {
                console.log(key1);
                console.log(texts[key], texts[key][key1])

                // console.log(texts[key], key, key1, value1, language)
                newObj[key][key1] = value1[language];
            });
        });
        return newObj;
    };

    const updateToken = (newToken) => {
        console.log("token updated");
        localStorage.setItem("token", newToken);
        setToken(newToken);
    };

    // const updatePermissions = async () => {
    //     if (!token) return;
    //     const response = request.get("/user/permissions")
    //     console.log(response.data)
    //     setPermissions(response.data)
    // }


    const updateUsers = async () => {
        if (!token) return;
        const res = await request.get("/user")
        setUsers(res.data)
    }

    const updateProperties = async (skip) => {
        if (!token) return;
        try {
            setIsLoading(true)
            const response = await request.get(`/property?page=${skip}`)
            setProperties(response.data.properties);
            setFilteredProperties(response.data.properties);
            setPageCount(response.data.totalPages)
            setCodes(response.data.codes)

            const response1 = await request.get("/property/new-code"); // Replace with your API endpoint
            setNewCode(response1.data.code);         
        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }
    };

    const updateFilters = async (formData, skip) => {
        const response = await request.post(`/property/filter?page=${skip}`, formData, {
            headers: { "Content-Type": "multipart/form-data; boundary=---XYZ123" },
        });

        setFilteredProperties(response.data.properties)
        setPageCount(response.data.totalPages)
    }

    const updateClients = async () => {
        if (!token) return;
        const res = await request.get("/client");
        setClients(res.data);
    };

    const updateClientTypes = async () => {
        if (!token) return;
        const res = await request.get("/client/get-types");
        setClientTypes(res.data);
    };

    const updateEmployee = async () => {
        if (!token) return;
        const res = await request.get("/employee/");
        setEmployee(res.data);
    };

    const updateEmployeeTypes = async () => {
        if (!token) return;
        const res = await request.get("employee/get-types");
        setEmployeeTypes(res.data);
    };

    const updateKeys = async () => {
        if (!token) return;
        const res = await request.get("/value"); // Replace with your API endpoint
        setKeys(res.data.values);
    };

    const updatePreLocationValues = async () => {
        if (!token) return;
        const res = await request.get("/pre-location/"); // Replace with your API endpoint
        setPreLocationValues(res.data);
    };

    useEffect(() => {
        if (token) {
            request.get("/user/my-permissions").then((res) => {
                if (JSON.stringify(permissions) !== JSON.stringify(res.data)) {
                    window.localStorage.setItem("premissions", JSON.stringify(res.data));
                    setPermissions(res.data);
                }
                refreshData();
            });
        }
    }, [token, permissions]);

    const refreshData = () => {
        updateKeys();
        updateClients();
        updateProperties();
        updateClientTypes();
        updateEmployee();
        updateEmployeeTypes();
        updatePreLocationValues();
        updateUsers()
    }

    return (
        <MainContext.Provider
            value={{
                properties,
                clients,
                keys,
                filteredProperties,
                clientTypes,
                employees,
                employeeTypes,
                permissions,
                users,
                pageCount,
                isLoading,
                codes,
                page,
                updateClientTypes,
                updateEmployee,
                updateClients,
                updateKeys,
                updateProperties,
                newCode,
                setProperties,
                setFilteredProperties,
                updateEmployeeTypes,
                updatePreLocationValues,
                preLocationValues,
                texts: flattenTexts(),
                language,
                switchLanguage,
                setErrorArr,
                updateToken,
                updateFilters,
                setPage,
                errorArr
            }}
        >
            {children}
        </MainContext.Provider>
    );
};
