import { useContext, useEffect, useState } from "react";
import AddUser from "./AddUser";
import UserList from "./UserList";
import EditUser from "./EditUser";
import CircularProgress from "@mui/material/CircularProgress";
import { MainContext } from "../../MainContext";

const UserForm = () => {
	const [edit, setEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { users } = useContext(MainContext)
  
  useEffect(() => {
    try {
      setIsLoading(true)
    } catch (error) {
      
    } finally {
      if (users !== undefined) {
        setIsLoading(false)
      }
    }
  }, [])

	return (
		<>
			{isLoading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<>
					{!edit ? <AddUser /> : <EditUser user_id={edit} setEdit={setEdit} />}
					<UserList setEdit={setEdit} />
				</>
			)}
		</>
	);
};

export default UserForm;
