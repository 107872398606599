import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, Divider, InputLabel, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../MainContext";

const PreferencesMainInputs: React.FC<{ control: any; setValue: any }> = ({ control, setValue }) => {
    const { texts, newCode, errorArr } = useContext(MainContext); // Assuming texts object is available in MainContext
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    return (
        <Box>
            <Divider style={{ margin: "30px 0" }}>{texts.maininput.mainInformation}</Divider>
            <Box gap={isMobile ? 1 : 5} sx={{ display: isMobile ? "block" : "flex" }}>
                <FormControl sx={{ borderRadius: "5px", marginBottom: isMobile? '15px' : '' }} component="fieldset">
                    <Controller
                        name="action"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <FormControl fullWidth sx={{ display: "flex" }} error={errorArr.includes(field.name) ? true : false}>
                                <InputLabel id="demo-simple-select-label">{texts.maininput.action}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={texts.maininput.action}
                                    sx={{ width:  isMobile ? "225px" : "250px" }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        console.log(e.target)
                                        field.onChange(value); // Update form value
                                        if (value >= 0 && value <= 1000) {
                                            setValue("action", value); // Sync slider
                                        }
                                    }}
                                >
                                    <MenuItem value={"sell"}>{texts.maininput.sell}</MenuItem>
                                    <MenuItem value={"rent"}>{texts.maininput.rent}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </FormControl>
                <br />
                <FormControl sx={{ borderRadius: "5px", marginBottom: isMobile? '15px' : ''  }} component="fieldset">
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <FormControl fullWidth error={errorArr.includes(field.name) ? true : false}>
                                <InputLabel id="demo-simple-select-label">{texts.maininput.type}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={texts.maininput.type}
                                    sx={{ width: isMobile ? "225px" : "250px" }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        field.onChange(value); // Update form value
                                        if (value >= 0 && value <= 1000) {
                                            setValue("type", value); // Sync slider
                                        }
                                    }}
                                >
                                    <MenuItem value={"apartment"}>{texts.maininput.apartment}</MenuItem>
                                    <MenuItem value={"house"}>{texts.maininput.house}</MenuItem>
                                    <MenuItem value={"commercial"}>{texts.maininput.commercial}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </FormControl>
                <br />
                <FormControl sx={{ borderRadius: "5px", height: "max-content", marginBottom: isMobile? '15px' : ''  }} component="fieldset">
                    <Controller
                        name="status"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <FormControl fullWidth error={errorArr.includes(field.name) ? true : false}>
                                <InputLabel id="demo-simple-select-label">{texts.maininput.status}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={texts.maininput.status}
                                    sx={{ width: isMobile ? "225px" : "250px" }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        field.onChange(value); // Update form value
                                        if (value >= 0 && value <= 1000) {
                                            setValue("status", value); // Sync slider
                                        }
                                    }}
                                >
                                    <MenuItem value={"pre-owned"}>{texts.maininput.preOwned}</MenuItem>
                                    <MenuItem value={"new"}>{texts.maininput.new}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </FormControl>
                <br />
                <FormControl sx={{ borderRadius: "5px" }} component="fieldset">
                    <Controller
                        name="buildingType"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <FormControl fullWidth error={errorArr.includes(field.name) ? true : false}>
                                <InputLabel id="demo-simple-select-label">{texts.maininput.buildingType}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={texts.maininput.buildingType}
                                    sx={{ width:  isMobile ? "225px" : "250px" }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        field.onChange(value); // Update form value
                                        if (value >= 0 && value <= 1000) {
                                            setValue("buildingType", value); // Sync slider
                                        }
                                    }}
                                >
                                    <MenuItem value={"Stone"}>{texts.maininput.stone}</MenuItem>
                                    <MenuItem value={"Panels"}>{texts.maininput.panels}</MenuItem>
                                    <MenuItem value={"Monolith"}>{texts.maininput.monolith}</MenuItem>
                                    <MenuItem value={"Bricks"}>{texts.maininput.bricks}</MenuItem>
                                    <MenuItem value={"Cassette"}>{texts.maininput.cassette}</MenuItem>
                                    <MenuItem value={"Wooden"}>{texts.maininput.wooden}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </FormControl>
            </Box>
        </Box>
    );
};

export default PreferencesMainInputs;
