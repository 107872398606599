export const texts = {
    navbar: {
        home: { en: "Home", arm: "Գլխավոր" },
        client: { en: "Client", arm: "Հաճախորդ" },
        employee: { en: "Employee", arm: "Աշխատակից" },
        property: { en: "Property", arm: "Գույք" },
    },
    propertyPage: {
        title: { en: "Property Manager", arm: "Գույքի կառավարիչ" },
        map: { en: "Map", arm: "Քարտեզ" },
    },
    propertyInput: {
        title: { en: "Upload Property", arm: "Գույք Վերաբեռնել" },
        validationErrors: { en: "Please fix the following errors before submitting:", arm: "Խնդրում ենք շտկել կարմիրով նշված պատուհանները:" },
        submitButton: { en: "Upload Property", arm: "Ուղարկել Գույքը" },
        location: { en: "Location", arm: "Տեղանք" },
    },
    maininput: {
        title: { en: "Main Information", arm: "Գլխավոր Ինֆորմացիա" },
        code: { en: "Code", arm: "Կոդ" },
        newCode: { en: "New Code", arm: "Նոր Կոդ" },
        action: { en: "Action", arm: "Գործողություն" },
        sell: { en: "Sell", arm: "Վաճառք" },
        rent: { en: "Rent", arm: "Վարձ" },
        type: { en: "Type", arm: "Տեսակ" },
        apartment: { en: "Apartment", arm: "Բնակարան" },
        house: { en: "House", arm: "Տուն" },
        commercial: { en: "Commercial", arm: "Բիզնես" },
        status: { en: "Status", arm: "Կարգավիճակ" },
        preOwned: { en: "Pre-owned", arm: "Օգտագործված" },
        new: { en: "New", arm: "Նոր" },
        buildingType: { en: "Building Type", arm: "Շենքի Տեսակ" },
        stone: { en: "Stone", arm: "Քարե" },
        panels: { en: "Panels", arm: "Պանելային" },
        monolith: { en: "Monolith", arm: "Մոնոլիտ" },
        bricks: { en: "Bricks", arm: "Կիրպիչ" },
        cassette: { en: "Cassette", arm: "Կասետ" },
        wooden: { en: "Wooden", arm: "Փայտե" },
    },
    locationInput: {
        title: { en: "Location Input", arm: "Տեղանքի մուտք" },
        lat: { en: "Latitude", arm: "Լատիտուտ" },
        lng: { en: "Longitude", arm: "Ջլանգիտուտ" },
        building: { en: "Building", arm: "Շենք" },
        apartment: { en: "Apartment", arm: "Բնակարան" },
        country: { en: "Country", arm: "Երկիր" },
        region: { en: "Region", arm: "Մարզ" },
        city: { en: "City", arm: "Քաղաք" },
        district: { en: "District", arm: "Շրջան" },
        street: { en: "Street", arm: "Փողոց" },
        addOption: { en: "Add", arm: "Ավելացնել" },
        noOptionsText: { en: "No options", arm: "Ոչ մի ընտրություն" }
    },
    numbersInput: {
        title: { en: "Numbers", arm: "Թվեր" },
        propertySurface: { en: "Property Surface", arm: "Գույքի մակերես" },
        ceiling: { en: "Ceiling", arm: "Առաստաղ" },
        rooms: { en: "Rooms", arm: "Սենյակներ" },
        bathrooms: { en: "Bathrooms", arm: "Լոգարաններ" },
        floor: { en: "Floor", arm: "Հարկ" },
        totalFloors: { en: "Total Floors", arm: "Ընդհանուր հարկեր" },
        renovation: { en: "Renovation", arm: "Վերանորոգում" }
    },
    propertiesInput: {
        title: {en: "Properties", arm: "Գույքեր"}
    },
    descriptionInput: {
        title: { en: "Descriptions", arm: "Նկարագրություններ" },
        description: { en: "Description", arm: "Նկարագրություն" },
        price: {en: "Price", arm: "Գին"}
    },
    propertyPersonInputs: {
        ownerTitle: { en: "Owner", arm: "Սեփականատեր" },
        owner: { en: "Owner", arm: "Սեփականատեր" },
        buyerTitle: { en: "Buyer", arm: "Գնորդ" },
        buyer: { en: "Buyer", arm: "Գնորդ" },
        agentTitle: { en: "Agent", arm: "Գործակալ" },
        agent: { en: "Agent", arm: "Գործակալ" }
    },
    propertyList: {
        price: { en: "Price", arm: "Գին" },
        uploaded: { en: "Uploaded", arm: "Տեղադրված" },
        renovation: { en: "Renovation", arm: "Վերանորոգում" },
        room: { en: "Room", arm: "Սենյականոց" },
        property_list: { en: "Property List", arm: "Գույքի ցանկ" },
    },
    watch: {
        description: { en: "Description", arm: "Նկարագրություն" },
        price: { en: "Price", arm: "Գին" },
        action: { en: "Action", arm: "Գործողություն" },
        sell: { en: "Sell", arm: "Վաճառք" },
        rent: { en: "Rent", arm: "Վարձ" },
        type: { en: "Type", arm: "Տիպ" },
        apartment: { en: "Apartment", arm: "Բնակարան" },
        house: { en: "House", arm: "Տուն" },
        commercial: { en: "Commercial", arm: "Բիզնես" },
        buildingType: { en: "Building Type", arm: "Շենքի տեսակ" },
        Stone: { en: "Stone", arm: "Քարե" },
        Panels: { en: "Panels", arm: "Պանելային" },
        Monolith: { en: "Monolith", arm: "Մոնոլիտ" },
        Bricks: { en: "Bricks", arm: "Կիրպիչ" },
        Cassette: { en: "Cassette", arm: "Կասետ" },
        Wooden: { en: "Wooden", arm: "Փայտե" },
        status: { en: "Status", arm: "Վիճակ" },
        preOwned: { en: "Pre-owned", arm: "Օգտագործված" },
        new: { en: "New", arm: "Նոր" },
        surface: { en: "Surface", arm: "Մակերես" },
        rooms: { en: "Rooms", arm: "Սենյակներ" },
        images: { en: "Images", arm: "Նկարներ" },
        documents: { en: "Documents", arm: "Փաստաթղթեր" },
        location: { en: "Location", arm: "Վայր" },
        lat: { en: "Latitude", arm: "Լատիտուտ" },
        lng: { en: "Longitude", arm: "Ջլանգիտուտ" },
        building: { en: "Building", arm: "Շենք" },
        Apartment: { en: "Apartment", arm: "Բնակարան" },
        country: { en: "Country", arm: "Երկիր" },
        region: { en: "Region", arm: "Մարզ" },
        city: { en: "City", arm: "Քաղաք" },
        district: { en: "District", arm: "Շրջան" },
        street: { en: "Street", arm: "Փողոց" },
        addOption: { en: "Add", arm: "Ավելացնել" },
        noOptionsText: { en: "No options", arm: "Ոչ մի ընտրություն" },
        currency: { en: "Currency", arm: "Արժույթ" },
        bathrooms: { en: "Bathrooms", arm: "Լոգարաններ" },
        ceiling: { en: "Ceiling", arm: "Առաստաղ" },
        renovation: { en: "Renovation", arm: "Վերանորոգում" },
        floor: { en: "Floor", arm: "Հարկ" },
        totalFloors: { en: "Total Floors", arm: "Ընդհանուր հարկեր" },
        other: { en: "Other", arm: "Այլ" },
        owner: { en: "Owner", arm: "Սեփականատեր" },
        buyer: { en: "Buyer", arm: "Գնորդ" },
        agent: { en: "Agent", arm: "Գործակալ" },
        propertyStatus: { en: "Property Status", arm: "Գույքի վիճակ" },
        code: { en: "Code", arm: "Կոդ" },
        name: { en: "Name", arm: "Անուն" },
        contact: { en: "Contact", arm: "Կապ" },
        comment: { en: "Comment", arm: "Մեկնաբանություն" },
        property_info: { en: "Information about flat", arm: "Տեղեկատվություն բնակարանի մասին" },
    },
    clientPage: {
        update_client: { en: "Update Client", arm: "Թարմացնել Հաճախորդին" },
        name: { en: "Name", arm: "Անուն" },
        contact: { en: "Contact", arm: "Կապ" },
        comment: { en: "Comment", arm: "Մեկնաբանություն" },
        employee: { en: "Employee", arm: "Աշխատակից" },
        action: { en: "Action", arm: "Գործողություն" },
        add_client: { en: "Add Client", arm: "Ավելացնել Հաճախորդ" },
        create_client: { en: "Create Client", arm: "Ավելացնել Հաճախորդին" },
        edit_client: { en: "Edit Client", arm: "Փոխել Հաճախորդին" },
        client_list: { en: "Client list", arm: "Հաճախորդների ցանկ" },
        other: { en: "Other", arm: "Այլ" },
        clientPreferences: { en: "Client Preferences", arm: "Հաճախորդի նախասիրություններ" },
        add_employee: { en: "Add Employee", arm: "Ավելացնել Աշխատակից" },
        create_employee: { en: "Create Employee", arm: "Ավելացնել Աշխատակցին" },
        edit_employee: { en: "Edit Employee", arm: "Փոխել Աշխատակցին" },
        update_employee: { en: "Update Employee", arm: "Թարմացնել Աշխատակցին" },
        employee_list: { en: "Employee list", arm: "Աշխատակիցների ցանկ" },
    },
    userPage: {
        login: { en: "Username", arm: "Օգտանուն" },
        password: { en: "Password", arm: "Գաղտնաբառ" },
        property: { en: "Property", arm: "Գույք" },
        user: { en: "User", arm: "Օգտատեր" },
        add_user: { en: "Add User", arm: "Ավելացնել օգտատեր" },
        edit_user: { en: "Edit User", arm: "Փոխել օգտատերին" },
        update_user: { en: "Update User", arm: "Թարմացնել օգտատերին" },
        create_user: { en: "Create User", arm: "Ավելացնել օգտատերին" },
        default: { en: "Default", arm: "Սովորական" },
        create_client: { en: "Create Client", arm: "Ավելացնել Հաճախորդին" },
        edit_client: { en: "Edit Client", arm: "Փոխել Հաճախորդին" },
        edit_own_client: { en: "Edit Own Client", arm: "Փոխել Սեփական Հաճախորդին" },
        own_client_list: { en: "Own Client List", arm: "Սեփական Հաճախորդի ցանկ" },
        add_property: { en: "Add Property", arm: "Ավելացնել Գույք" },
        edit_property: { en: "Edit Property", arm: "Փոխել Գույքը" },
        employee: { en: "Employee", arm: "Աշխատակից" },
        client: { en: "Client", arm: "Հաճախորդ" },
        get_own: { en: "Own", arm: "Սեփական" },
        home: { en: "Home", arm: "Գլխավոր" },
        user_list: { en: "User List", arm: "Օգտատերների ցանկ" },
        action: { en: "Action", arm: "Գործողություն" },
        permissions: { en: "Permissions", arm: "Թույլտվություններ" }
    }
};
