import axios from "axios"

console.log(process.env)

export const request = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL + '/api',
})

request.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("token");
        if (token) {
            config.headers["token"] = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);