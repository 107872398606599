import { Box, Divider, Typography, Checkbox, Card, CardContent } from "@mui/material";
import { useContext } from "react";
import { MainContext } from "../../MainContext";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Premissions = ({ values, setValues }) => {
    const {texts} = useContext(MainContext)

    return (
        <Box sx={{ padding: 3, display: window.innerWidth < 500 ? "block" : 'flex', justifyContent: 'space-between' }}>
            {
                Object.entries(values ?? {}).map(([key1, value1]) => (
                    <Card key={key1} sx={{ marginBottom: 4, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                                {texts.userPage[key1]}
                            </Typography>
                            <Divider sx={{ marginBottom: 3 }} />
                            {
                                Object.entries(value1).map(([key2, value2]) => (
                                    <Box
                                        key={key2}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: 2,
                                            padding: 2,
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: 1,
                                            boxShadow: 1,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ flex: 1 }}>
                                            {texts.userPage[key2]}
                                        </Typography>
                                        <Checkbox
                                            {...label}
                                            checked={value2}
                                            onChange={(e) => {
                                                const newValues = { ...values };
                                                newValues[key1][key2] = e.target.checked;
                                                setValues(newValues);
                                            }}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                        />
                                    </Box>
                                ))
                            }
                        </CardContent>
                    </Card>
                ))
            }
        </Box>
    );
};

export default Premissions;
