import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { IProperty } from "../../interfaces/property";
import "./ComponentPage.css";
import Modal from "@mui/material/Modal";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CreateIcon from "@mui/icons-material/Create";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import { MainContext } from "../../MainContext";
import anime from "animejs/lib/anime.es.js";
import CustomCarousel from "../CustomSlider";
import { request } from "../../request";

const actions = [
	{ icon: <CreateIcon />, name: "Edit" },
	{ icon: <UpgradeIcon />, name: "Update status" },
];

const ComponentPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [property, setProperty] = useState<IProperty>();
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const [selectedImg, setSelectedImg] = useState(0);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { texts, clients, employees } = useContext(MainContext);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: window.innerWidth > 500 && dimensions.width > dimensions.height ? 1000 : 700,
		height: dimensions.width < dimensions.height ? 1000 : "auto",
		border: "none",
		boxShadow: 24,
		outline: "none",
		p: 4,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const handleOpen = (id: number) => {
		setSelectedImg(id);
		const img = new Image();
		img.src =
			process.env.REACT_APP_BACK_URL +
			"/api/" +
			"uploads/" +
			property?.images.find((_, index) => index === id);

		img.onload = () => {
			setDimensions({ width: img.width, height: img.height });
			setOpen(true);
		};

		img.onerror = () => {
			console.error("Error loading the image");
		};
	};

	const getProperty = async () => {
		try {
			const response = await request.get("/property/" + id);
			setProperty(response.data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getProperty();
	}, []);

	// useEffect(() => {
	// 	if (open && property?.images) {
	// 		// Ensures the modal gets the latest data
	// 		setTimeout(() =>handleOpen(true), 50);
	// 	}
	// }, [open, property]);

	useEffect(() => {
		anime({
			targets: ".img-container",
			translateX: {
				value: [-1000, 0],
			},
			duration: 2000,
			easing: "easeOutExpo",
		});
		anime({
			targets: ".property-description",
			translateX: {
				value: [1000, 0],
			},
			duration: 2000,
			easing: "easeOutExpo",
		});
	}, [property]);
	console.log(property);

	const handleAction = async (name: string) => {
		if (name === "Update status") {
			try {
				Swal.fire({
					title: "Are you sure?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Update status",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await request.post("/property/update-status/" + id);
						Swal.fire({
							title: "Property status updated",
							icon: "success",
						});
					}
				});
			} catch (error) {
				console.log(error);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: `${error}`,
				});
			}
		} else if (name === "Edit") {
			navigate(`/component/edit/${id}`);
		}
	};

	const handleNext = () => {
		if (selectedImg !== null && property?.images) {
			setSelectedImg((prev) => (prev + 1) % property.images.length);
			const img = new Image();
			img.src =
				process.env.REACT_APP_BACK_URL +
				"/api/" +
				"uploads/" +
				property?.images.find((_, index) => index == (selectedImg + 1) % property.images.length);
	
			img.onload = () => {
				setDimensions({ width: img.width, height: img.height });
			};
		}
	};

	const handlePrev = () => {
		if (selectedImg !== null && property?.images) {
			setSelectedImg((prev) => (prev - 1 + property.images.length) % property.images.length);
			const img = new Image();
			img.src =
				process.env.REACT_APP_BACK_URL +
				"/api/" +
				"uploads/" +
				property?.images.find((_, index) => index == (selectedImg - 1 + property.images.length) % property.images.length);
	
			img.onload = () => {
				setDimensions({ width: img.width, height: img.height });
			};
		}
	};


	return (
		<>
			{isLoading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<div className="main-component">
					<Modal open={open} onClose={handleClose}>
						<Box sx={style}>
							{/* <IconButton
								sx={{ position: "absolute", top: 10, right: 10, color: "white" }}
								onClick={handleClose}
							>
								<Close />
							</IconButton> */}

							<IconButton
								sx={{ position: "absolute", left: -70, color: "white" }}
								onClick={handlePrev}
							>
								<ArrowBackIos fontSize="large"/>
							</IconButton>

							<img
								src={
									process.env.REACT_APP_BACK_URL +
									"/api/uploads/" +
									property?.images?.[selectedImg ?? 0]
								}
								alt="Preview"
							/>

							<IconButton
								sx={{ position: "absolute", right: -70, color: "white" }}
								onClick={handleNext}
							>
								<ArrowForwardIos fontSize="large"/>
							</IconButton>
						</Box>
					</Modal>
					{/* Left side: Image Carousel */}
					<div className="img-container">
						<CustomCarousel>
							{property?.images.map((image, index) => {
								return (
									<img
										key={index}
										src={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + image}
										alt={image}
										onClick={() => handleOpen(index)}
									/>
								);
							})}
						</CustomCarousel>
						<div className="mini-images">
							{property?.images.map((image, index) => {
								return (
									<img
										key={index}
										src={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + image}
										alt={image}
										onClick={() => handleOpen(index)}
									/>
								);
							})}
						</div>
					</div>

					<div className="property-description">
						{Object.entries(property || {})
							.filter(([k, _]) => k === "code")
							.map(([k, v]) => (
								<div className="code">
									<h2 className="">
										{texts.watch[k]}: {v}
									</h2>
								</div>
							))}
						{["location"].map((key) => {
							//@ts-ignore
							const value: Object | string = property?.[key];
							return (
								value && (
									<div key={key} className="nested-item">
										<strong className="nested-key">{texts.watch[key]} </strong>
										<div className="nested-object">
											{typeof value === "object" ? (
												<div>
													{Object.entries(value ? value : {}).map(([key2, value2]) => (
														<>
															{value2 ? (
																<div className="rows-container">
																	<p className="row-key">{texts.watch[key2]}</p>
																	<p>-</p>
																	<p className="row-value" style={{ marginLeft: "20px" }}>
																		{value2}
																	</p>
																</div>
															) : (
																""
															)}
														</>
													))}
												</div>
											) : (
												<span>
													{(typeof value !== "object" && typeof value === "string") ||
													typeof value === "number"
														? value
														: ""}
												</span>
											)}
										</div>
									</div>
								)
							);
						})}
						<div className="nested-item">
							<strong className="nested-key">{texts.watch["property_info"]}</strong>
							<div className="nested-object">
								<div>
									{[
										"type",
										"action",
										"buildingType",
										"surface",
										"ceiling",
										"bathrooms",
										"rooms",
										"floor",
										"renovation",
										"status",
										"price",
									].map((key) => {
										//@ts-ignore
										const value = property?.[key];
										let currency: string | undefined = "";
										let price = "";
										if (key === "price") {
											currency = property?.["currency"];
											price = `${value} ${currency}`;
										}
										return (
											value && (
												<div className="rows-container">
													<p className="row-key">{texts.watch[key]}</p>
													<p>-</p>
													<p className="row-value" style={{ marginLeft: "20px" }}>
														{texts.watch[value]
															? texts.watch[value]
															: `${currency !== "" ? price : value}`}
													</p>
												</div>
											)
										);
									})}
								</div>
							</div>
						</div>
						{["other"].map((key) => {
							//@ts-ignore
							const value: Object | string = property?.[key];
							return (
								value && (
									<div key={key} className="nested-item">
										<strong className="nested-key">{texts.watch[key]}</strong>
										<div className="nested-object">
											{typeof value === "object" ? (
												<div>
													{Object.entries(value ? value : {}).map(([key2, value2]) => (
														<p className="nested-el">{key2}</p>
													))}
												</div>
											) : (
												<span>
													{(typeof value !== "object" && typeof value === "string") ||
													typeof value === "number"
														? value
														: ""}
												</span>
											)}
										</div>
									</div>
								)
							);
						})}
						{["owner"].map((key) => {
							//@ts-ignore
							const value: Object = property?.[key];
							return (
								value && (
									<div key={key} className="nested-item">
										<strong className="description-key">{texts.watch[key]} </strong>
										<span className="">
											{typeof value === "object" ? (
												<div>
													{Object.entries(value ? value : {}).map(([key2, value2]) => (
														<>
															{key2 !== "_id" ? (
																<p className="nested-el">
																	{texts.watch[key2]}: {value2}
																</p>
															) : (
																""
															)}
														</>
													))}
												</div>
											) : (
												<span>
													{(typeof value !== "object" && typeof value === "string") ||
													typeof value === "number"
														? value
														: ""}
												</span>
											)}
										</span>
									</div>
								)
							);
						})}
						{["agent"].map((key) => {
							//@ts-ignore
							const value = property?.[key];
							return (
								value && (
									<div key={key} className="description-item top-priority">
										<strong className="description-key">{texts.watch[key]} </strong>
										<span className="description-value">
											{[...clients, ...employees].find((e) => e._id === value)?.name} (
											{[...clients, ...employees].find((e) => e._id === value)?.contact})
										</span>
									</div>
								)
							);
						})}
						{["description"].map((key) => {
							//@ts-ignore
							const value = property?.[key];
							return (
								value && (
									<div key={key} className="description-item top-priority">
										<strong className="description-key">{texts.watch[key]} </strong>
										<div className="">
											<p>{value}</p>
										</div>
									</div>
								)
							);
						})}
					</div>
				</div>
			)}
			<Box sx={{ height: isMobile ? 10 : 120, transform: "translateZ(0px)", flexGrow: 1 }}>
				<SpeedDial
					ariaLabel="SpeedDial basic example"
					sx={{
						position: isMobile ? "absolute" : "absolute",
						bottom: isMobile ? 40 : 40,
						right: isMobile ? 15 : 26,
					}}
					icon={<SpeedDialIcon />}
				>
					{actions.map((action) => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={() => handleAction(action.name)}
						/>
					))}
				</SpeedDial>
			</Box>
		</>
	);
};

export default ComponentPage;
