import { Box, FormControl, Select, MenuItem, Divider, useMediaQuery, useTheme } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { MainContext } from "../../MainContext";

const EmployeeFiled: React.FC<{employee: any, setEmployee:any}> = ({employee, setEmployee}) => {
    const { employees, texts, errorArr } = useContext(MainContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));




    return (
        <Box sx={{ width: isMobile ? "92%" : "100%", display: isMobile ? 'block' : '' }}>
            <Divider style={{ margin: "30px 0" }}>{texts.propertyPersonInputs.agentTitle}</Divider>

            <FormControl fullWidth sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "stretch" : "center" }} error={errorArr.includes('agent') ? true : false}>
                <InputLabel id="demo-simple-select-label">{texts.propertyPersonInputs.agent}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={employee}
                    label="Clients"
                    onChange={(e) => setEmployee(e.target.value)}
                    sx={{
                        width: isMobile ? "100%" : "100%",
                        marginBottom: isMobile ? "10px" : "0",
                    }}
                >
					{employees.length > 1 ? (
						employees.map((client: any) => <MenuItem value={client._id}> {client.name}</MenuItem>)
                    ) : (
						<MenuItem value={employees._id}> {employees.name}</MenuItem>
					)}
                </Select>
            </FormControl>
        </Box>
    );
};

export default EmployeeFiled;
