import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Grid,
	PaginationItem,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import axios from "axios";
import { IProperty } from "../../interfaces/property";
import { MainContext } from "../../MainContext";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Pagination from "@mui/material/Pagination";
import anime from "animejs/lib/anime.es.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PropertyList: React.FC = () => {
	const navigate = useNavigate();
	const {
		texts,
		filteredProperties,
		updateProperties,
		pageCount,
		isLoading,
		updateFilters,
		page,
		setPage,
		language
	} = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const goToComponent = (id: string) => {
		navigate(`/component/${id}`);
	};

	// useEffect(() => {
	//     anime({
	//       targets: ".property-card",
	//       opacity: [0, 1],
	//       translateY: [50, 0],
	//       duration: 2000,
	//       easing: "easeOutExpo",
	//       delay: anime.stagger(200),
	//     });
	//   }, [filteredProperties]);

	console.log(filteredProperties);
	const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
		window.scrollTo(0, 0);
		setPage(value);
		updateProperties(value);
		updateFilters({}, value);
	};

	return (
		<>
			{filteredProperties.length > 0 ? (
				<>
					{isLoading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100vh",
							}}
						>
							<CircularProgress />
						</div>
					) : (
						<Grid container spacing={4}>
							{filteredProperties.length > 1 ? (
								filteredProperties.map((property: IProperty) => (
									<Grid item xs={12} sm={6} md={4} key={property._id}>
										<Card
											onClick={() => goToComponent(property._id)}
											sx={{ "&:hover": { cursor: "pointer" } }}
											className="property-card"
										>
											{property.images[0] && (
												<CardMedia
													component="img"
													height="200px"
													sx={{ height: "25vh" }}
													image={
														process.env.REACT_APP_BACK_URL +
														"/api/" +
														"uploads/" +
														property.images[0]
													}
													alt={property.description}
												/>
											)}
											<CardContent>
												<Box sx={{ display: "flex" }}>
													<Typography variant="h6">
														{property.rooms} {texts.propertyList.room} {property.type === "apartment" ? texts.watch.apartment : property.type === "house" ? texts.watch.house : texts.watch.commercial} {language === "arm" ? "" : "on"}{" "}
														{property.location?.street}ում
													</Typography>
													<svg
														fill={property.propertyStatus}
														width="64px"
														height="64px"
														viewBox="0 0 256 256"
														xmlns="http://www.w3.org/2000/svg"
													>
														<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
														<g
															id="SVGRepo_tracerCarrier"
															stroke-linecap="round"
															stroke-linejoin="round"
														></g>
														<g id="SVGRepo_iconCarrier">
															{" "}
															<circle cx="127" cy="129" r="81" fill-rule="evenodd"></circle>{" "}
														</g>
													</svg>
												</Box>
												<Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold'}}>
													{texts.maininput.code}: {property.code}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{texts.propertyList.price}: {property.price} {property.currency}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{texts.propertyList.uploaded}:{" "}
													{new Date(property.createdAt).toLocaleDateString()}
												</Typography>
												<Typography
													variant="body2"
													color="text.secondary"
													sx={{ display: "flex", alignItems: "center" }}
												>
													{texts.propertyList.renovation}:{" "}
													<Rating
														name="read-only"
														value={property.renovation}
														readOnly
														max={7}
														sx={{ marginLeft: "10px" }}
													/>
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							) : (
								<Grid item xs={12} sm={6} md={4} key={filteredProperties[0]._id}>
									<Card
										onClick={() => goToComponent(filteredProperties[0]._id)}
										sx={{ "&:hover": { cursor: "pointer" } }}
										className="property-card"
									>
										{filteredProperties[0]?.images[0] &&
											filteredProperties[0].images !== undefined && (
												<CardMedia
													component="img"
													height="200px"
													sx={{ height: "25vh" }}
													image={
														process.env.REACT_APP_BACK_URL +
														"/api/" +
														"uploads/" +
														filteredProperties[0].images[0]
													}
													alt={filteredProperties[0].description}
												/>
											)}
										<CardContent>
											<Box sx={{ display: "flex" }}>
												<Typography variant="h6">
													{filteredProperties[0].rooms} Bedroom {filteredProperties[0].action} for{" "}
													{filteredProperties[0].action} on {filteredProperties[0].location?.street}
												</Typography>
												<svg
													fill={filteredProperties[0].propertyStatus}
													width="64px"
													height="64px"
													viewBox="0 0 256 256"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
													<g
														id="SVGRepo_tracerCarrier"
														stroke-linecap="round"
														stroke-linejoin="round"
													></g>
													<g id="SVGRepo_iconCarrier">
														{" "}
														<circle cx="127" cy="129" r="81" fill-rule="evenodd"></circle>{" "}
													</g>
												</svg>
											</Box>
											<Typography variant="body2" color="text.secondary">
												{texts.propertyList.price}: {filteredProperties[0].price}{" "}
												{filteredProperties[0].currency}
											</Typography>
											<Typography variant="body2" color="text.secondary">
												{texts.propertyList.uploaded}:{" "}
												{new Date(filteredProperties[0].createdAt).toLocaleDateString()}
											</Typography>
											<Typography
												variant="body2"
												color="text.secondary"
												sx={{ display: "flex", alignItems: "center" }}
											>
												{texts.propertyList.renovation}:{" "}
												<Rating
													name="read-only"
													value={filteredProperties[0].renovation}
													readOnly
													max={7}
													sx={{ marginLeft: "10px" }}
												/>
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							)}
							{filteredProperties.length ? (
								<Stack
									spacing={2}
									sx={{
										marginTop: "20px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
									}}
								>
									<Pagination
										size={isMobile ? "medium" : "large"}
										count={pageCount}
										page={page}
										onChange={handleChange}
										renderItem={(item) => (
											<PaginationItem
												slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
												{...item}
											/>
										)}
									/>
								</Stack>
							) : (
								""
							)}
						</Grid>
					)}
				</>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50vh",
						textAlign: "center",
					}}
				>
					{!isLoading && (
						<Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
							🚀 Nothing Found! Try adjusting your filters.
						</Typography>
					)}
				</Box>
			)}
		</>
	);
};

export default PropertyList;
