import {
	Box,
	FormControl,
	Select,
	MenuItem,
	Divider,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { SelectChangeEvent } from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AddEmployee from "../../Employee/AddEmployee";
import { CustomizableInputProps } from "../../../interfaces/formInputs";

const EmployeeInput: React.FC<CustomizableInputProps<string> & { setValue: any; watch: any }> = ({
	onChange,
	setValue,
	watch,
}) => {
	const [showAdder, setShowAdder] = useState(false);
	const { employees, texts, errorArr } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const employee = watch("agent");

	const handleChange = (event: SelectChangeEvent) => {
		setValue("agent", event.target.value);
	};

	const openAdder = () => {
		setShowAdder((e) => !e);
	};

	useEffect(() => {
		onChange({
			target: { name: "agent", value: employee },
		});
	}, [employee]);

	console.log(employees.name);
	return (
		<Box
			sx={{ width: isMobile ? "92%" : "100%", margin: "10px", display: isMobile ? "block" : "" }}
		>
			<Divider style={{ margin: "30px 0" }}>{texts.propertyPersonInputs.agentTitle}</Divider>

			<FormControl
				fullWidth
				sx={{
					display: "flex",
					flexDirection: isMobile ? "column" : "row",
					alignItems: isMobile ? "stretch" : "center",
				}}
				error={errorArr.includes("agent") ? true : false}
			>
				<InputLabel id="demo-simple-select-label">{texts.propertyPersonInputs.agent}</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={employee}
					label="Clients"
					onChange={handleChange}
					sx={{
						width: isMobile ? "100%" : "92%",
						marginBottom: isMobile ? "10px" : "0",
					}}
				>
					{employees.length > 1 ? (
						employees.map((client: any) => <MenuItem value={client._id}> {client.name}</MenuItem>)
                    ) : (
						<MenuItem value={employees._id}> {employees.name}</MenuItem>
					)}
				</Select>
				<Fab
					color="primary"
					aria-label="add"
					onClick={openAdder}
					sx={{
						marginLeft: isMobile ? "10px" : "2%",
						alignSelf: isMobile ? "center" : "center",
					}}
					size="medium"
				>
					<AddIcon />
				</Fab>
			</FormControl>
			{showAdder ? <AddEmployee /> : ""}
		</Box>
	);
};

export default EmployeeInput;
