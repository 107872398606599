import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	IconButton,
	useMediaQuery,
	useTheme
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import { useForm, SubmitHandler } from "react-hook-form";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import { request } from "../../request";
import ClientPreferences from "./ClientPreferences";

interface FormInputs {
	action: "rent" | "sell";
	type: "apartment" | "house" | "commercial";
	buildingType: "Stone" | "Panels" | "Monolith" | "Bricks" | "Cassette" | "Wooden";
	status: "pre-owned" | "new";
	surface: number;
	rooms: number;
	bathrooms: number;
	ceiling: number;
	renovation: number;
	floor: number;
	totalFloors: number;
}

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
}

const EditClient: React.FC<{ client_id: any; setEdit: any }> = ({ client_id, setEdit }) => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState("");
	const { updateClients, clients, texts } = useContext(MainContext);
	const client = clients.find((e: any) => e._id === client_id);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [formData, setFormData] = useState<IFormData>({
		name: client?.name || "",
		contact: client?.contact || [],
		comment: client?.comment || "",
	});
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		getValues,
		control,
		watch,
	} = useForm<FormInputs>({
		defaultValues: {
			action: client.action,
			type: client.type,
			buildingType: client.buildingType,
			status: client.status,
			surface: client.surface,
			rooms: client.rooms,
			bathrooms: client.bathrooms,
			ceiling: client.ceiling,
			renovation: client.renovation,
			floor: client.floor,
			totalFloors: client.totalFloors
		},
	});

	useEffect(() => {
		setValues([...values, ...client.contact]);
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		try {
			const preferences = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value) {
                    if (typeof value === "object") {
                        preferences.append(key, JSON.stringify(value));
					} else {
                        preferences.append(key, value);
                    }
                }
			});

			preferences.append("name", formData.name);
			preferences.append("contact", JSON.stringify(formData.contact));
			preferences.append("comment", formData.comment);

			await request.post("/client/" + client_id, preferences, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			});
			Swal.fire({
				title: "Client updated successfully",
				icon: "success",
			});
			setFormData({
				name: "",
				contact: [],
				comment: "",
			});
			setEdit(null);
			setValues([]);
			updateClients();
		} catch (error) {
			console.error("Error creating client:", error);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...formData.contact, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};

	const handleDelete = (valueToDelete: string) => {
		const updatedValues = formData.contact.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	return (
		<Box
			component="form"
			sx={{ maxWidth: isMobile ? 320 : 1300, mx: "auto", mt: 4, p: 4, border: "1px solid #ccc", borderRadius: 2 }}
		>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
				<Typography variant="h5">
					{texts.clientPage.edit_client}
				</Typography>
                <IconButton
                    onClick={() => setEdit(null)}
                >
					<CancelIcon sx={{fontSize: "34px"}} />
				</IconButton>
			</Box>
			<TextField
				label={texts.clientPage.name}
				name="name"
				value={formData.name}
				onChange={handleChange}
				fullWidth
				required
				margin="normal"
			/>
			<Box sx={{ marginTop: "10px" }}>
				<TextField
					label={texts.clientPage.contact}
					variant="outlined"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleKeyDown}
					fullWidth
				/>
				<Box mt={2}>
					{values.map((value) => (
						<Chip
							key={value}
							label={value}
							onDelete={() => handleDelete(value)}
							style={{ margin: "5px" }}
						/>
					))}
				</Box>
			</Box>
			<TextField
				label={texts.clientPage.comment}
				name="comment"
				value={formData.comment}
				onChange={handleChange}
				fullWidth
				multiline
				margin="normal"
			/>
			<ClientPreferences control={control} setValue={setValue} watch={watch} />
			<Button
				type="button"
				onClick={handleSubmit(onSubmit)}
				variant="contained"
				color="primary"
				fullWidth
				sx={{ mt: 2 }}
			>
				{texts.clientPage.update_client}
			</Button>
		</Box>
	);
};

export default EditClient;
