import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, FormControl, FormHelperText, TextField, Autocomplete, Button, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import axios from "axios";
import { CustomizableInputProps } from "../../../interfaces/formInputs";
import { ILocation } from "../../../interfaces/location";
import { MainContext } from "../../../MainContext";
import { request } from "../../../request";
import MapComponent from "../Map";
import FilterMap from "./FilterMap";



const FilterLocationInput: React.FC<CustomizableInputProps<ILocation>> = ({ onChange }) => {
    const { register, setValue, watch, control } = useForm<ILocation>();
    const { preLocationValues, updatePreLocationValues, texts } = useContext(MainContext);
    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const prevLocationFields = useRef<any>();
    const blockInputValues = useRef<boolean>();
    const [inputValue, setInputValue] = useState<Record<string, string>>({});

    const addNewPreLocationValue = async (type: string, value: string) => {
        await request.post("/pre-location", { type, value });
        await updatePreLocationValues(); // Refresh options from the backend
        setValue(type as keyof ILocation, value); // Set the new value in the form
    };

    const handleAddButtonClick = async (field: string) => {
        const value = inputValue[field];
        if (value) {
            await addNewPreLocationValue(field, value);
            setInputValue({ ...inputValue, [field]: value }); // Reset input for the field
        }
    };

    // Watch for changes in form fields
    const locationFields = watch();

    // Notify the parent when locationFields change
    useEffect(() => {
        if (JSON.stringify(locationFields) !== JSON.stringify(prevLocationFields.current)) {
            onChange({
                target: {
                    name: "location",
                    value: locationFields,
                },
            });
            prevLocationFields.current = locationFields;
        }
    }, [locationFields]);


    return (
        <Box sx={{ borderRadius: "5px", margin: "20px 0" }}>
            <Box>
                <Controller
                    name="lat"
                    control={control}
                    render={({ field }) => (
                        <FilterMap
                            {...field}
                            onChange={(location: ILocation) => {
                                const newInputValue = { ...inputValue };
                                Object.entries(location).forEach(([key, value]) => {
                                    //@ts-ignore
                                    setValue(key, value);
                                    newInputValue[key] = value;
                                    if (!preLocationValues[key]?.includes(value) && value) {
                                        addNewPreLocationValue(key, value);
                                    }
                                });
                                setInputValue(newInputValue);
                                blockInputValues.current = true;
                                setTimeout(() => {
                                    blockInputValues.current = false;
                                }, 500);
                            }}
                        />
                    )}
                />
                {["country", "region", "city", "district", "street"].map((field) => (
                    <FormControl sx={{ width: isMobile ? "90%" : "400px", marginLeft: isMobile ? '20px' : "" }} margin="normal" variant="standard" key={field}>
                        <Autocomplete
                            options={preLocationValues[field] || []}
                            inputValue={inputValue[field] || ""}
                            value={inputValue[field] || ""}
                            onInputChange={(event, newInputValue) => {
                                console.log(newInputValue)
                                if (newInputValue !== inputValue[field] && !blockInputValues.current) {
                                    setInputValue({ ...inputValue, [field]: newInputValue });
                                }
                            }}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setInputValue({ ...inputValue, [field]: newValue });
                                    setValue(field as keyof ILocation, newValue);
                                }else {
                                    setInputValue({ ...inputValue, [field]: "" });
                                    setValue(field as keyof ILocation, "");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} {...register(field as keyof ILocation, { required: true })} label={texts.locationInput[field]} />}
                            renderOption={(props, option: string) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                            noOptionsText={
                                inputValue[field] && (
                                    <Button onClick={() => handleAddButtonClick(field)} size="small" variant="contained" color="primary">
                                        {texts.locationInput.addOption} "{inputValue[field]}"
                                    </Button>
                                )
                            }
                        />
                    </FormControl>
                ))}
                <FormControl margin="normal" variant="standard" sx={{width: isMobile ? "90%" : "400px", marginLeft: isMobile ? '20px' : "" }}>
                    <TextField type="text" {...register("building", { required: true })} />
                    <FormHelperText>{texts.locationInput.building}</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard" sx={{width: isMobile ? "90%" : "400px", marginLeft: isMobile ? '20px' : "" }}>
                    <TextField type="number" {...register("apartment")} />
                    <FormHelperText>{texts.locationInput.apartment}</FormHelperText>
                </FormControl>
                <br />
                <FormControl margin="normal" variant="standard" sx={{width: isMobile ? "90%" : "400px", marginLeft: isMobile ? '20px' : "" }}>
                    <TextField {...register("lat", { required: true })} />
                    <FormHelperText>{texts.locationInput.lat}</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard" sx={{width: isMobile ? "90%" : "400px", marginLeft: isMobile ? '20px' : "" }}>
                    <TextField {...register("lng", { required: true })} />
                    <FormHelperText>{texts.locationInput.lng}</FormHelperText>
                </FormControl>
            </Box>
        </Box>
    );
};

export default FilterLocationInput;
