import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import { request } from "../../request";

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
	tg_username: string;
}

const EditEmployee: React.FC<{ employee_id: any; setEdit: any }> = ({ employee_id, setEdit }) => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState("");
	const { employees, updateEmployee, texts } = useContext(MainContext);
	const employee = employees.find((e: any) => e._id === employee_id);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [formData, setFormData] = useState<IFormData>({
		name: employee?.name,
		contact: employee?.contact,
		comment: employee?.comment,
		tg_username: employee?.tg_username,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		console.log(name);
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		setValues([...values, ...employee.contact]);
	}, []);

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await request.post("/employee/" + employee_id, formData); // Adjust endpoint as needed
			Swal.fire({
				title: "Agent updated successfully",
				icon: "success",
			});
			setFormData({
				name: "",
				contact: [],
				comment: "",
				tg_username: "@",
			});
			setEdit(null);
			setValues([]);
			updateEmployee();
		} catch (error) {
			console.error("Error creating agent:", error);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...formData.contact, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};
	console.log(formData);
	const handleDelete = (valueToDelete: string) => {
		const updatedValues = formData.contact.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	return (
		<Box
			component="form"
			sx={{
				maxWidth: isMobile ? 320 : 400,
				mx: "auto",
				mt: 4,
				p: 4,
				border: "1px solid #ccc",
				borderRadius: 2,
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography variant="h5">{texts.clientPage.edit_employee}</Typography>
				<IconButton onClick={() => setEdit(null)}>
					<CancelIcon sx={{ fontSize: "34px" }} />
				</IconButton>
			</Box>
			<TextField
				label={texts.clientPage.name}
				name="name"
				value={formData.name}
				onChange={handleChange}
				fullWidth
				required
				margin="normal"
			/>
			<Box sx={{ marginTop: "10px" }}>
				<TextField
					label={texts.clientPage.contact}
					variant="outlined"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleKeyDown}
					fullWidth
				/>
				<Box mt={2}>
					{values.map((value) => (
						<Chip
							key={value}
							label={value}
							onDelete={() => handleDelete(value)}
							style={{ margin: "5px" }}
						/>
					))}
				</Box>
			</Box>
			<TextField
				label={texts.clientPage.comment}
				name="comment"
				value={formData.comment}
				onChange={handleChange}
				fullWidth
				multiline
				margin="normal"
			/>
			<TextField
				label="TG username"
				name="tg_username"
				value={formData.tg_username}
				onChange={handleChange}
				fullWidth
				multiline
				margin="normal"
			/>
			<Button
				type="button"
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				fullWidth
				sx={{ mt: 2 }}
			>
				{texts.clientPage.update_employee}
			</Button>
		</Box>
	);
};

export default EditEmployee;
