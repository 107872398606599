import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useTheme,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import { MainContext } from "../../MainContext";
import Swal from "sweetalert2";
import Premissions from "./Permissions";
import { request } from "../../request";
import CancelIcon from "@mui/icons-material/Cancel";
import EmployeeFiled from "./EmployeeField";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const EditUser: React.FC<{ user_id: any; setEdit: any }> = ({ user_id, setEdit }) => {
	const { users, employees, texts } = useContext(MainContext);
	const user = users.find((e: any) => e._id === user_id);
	console.log(user)
	const employeeName = employees.length > 1 ? employees.find((e: any) => e._id === user.owner) : employees
	console.log(employeeName)

	const [login, setLogin] = useState(user?.login);
	const [pass, setPass] = useState(user?.pass);
	const [values, setValues] = useState({});
	const [employee, setEmployee] = useState(employeeName?._id)
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const {updateUsers} = useContext(MainContext)

	useEffect(() => {
		if (typeof user?.permissions === "object" && !Array.isArray(user?.permissions)) {
			setValues((prevValues) => ({
				...prevValues,
				...user.permissions,
			}));
		}
	}, [user]);

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await request.post("/user/edit/" + user_id, {
				login: login,
				pass: pass,
				permissions: values,
				owner: employee
			});
			Swal.fire({
				title: "User updated successfully",
				icon: "success",
			});
			setEdit(null);
			updateUsers()
		} catch (error) {
		}
	};

	return (
		<>
			<Box
				component="form"
				sx={{
					maxWidth: isMobile ? 320 : 1300,
					mx: "auto",
					mt: 4,
					p: 4,
					border: "1px solid #ccc",
					borderRadius: 2,
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Typography variant="h5">{texts.userPage.edit_user}</Typography>
					<IconButton onClick={() => setEdit(null)}>
						<CancelIcon sx={{ fontSize: "34px" }} />
					</IconButton>
				</Box>
				<TextField
					label={texts.userPage.login}
					name={texts.userPage.login}
					value={login}
					fullWidth
					required
					margin="normal"
					onChange={(e) => setLogin(e.target.value)}
				/>
				<TextField
					label={texts.userPage.password}
					name={texts.userPage.password}
					value={pass}
					fullWidth
					multiline
					margin="normal"
					onChange={(e) => setPass(e.target.value)}
				/>
				<EmployeeFiled employee={employee} setEmployee={setEmployee} />
				<Premissions values={values} setValues={setValues} />
				<Button
					type="button"
					variant="contained"
					color="primary"
					fullWidth
					sx={{ mt: 2 }}
					onClick={handleSubmit}
				>
					{texts.userPage.update_user}
				</Button>
			</Box>
		</>
	);
};

export default EditUser;
