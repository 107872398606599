import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { MainContext } from "../../MainContext";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Swal from "sweetalert2";

const permissionsStyle = {
	container: {
		padding: "1rem",
		borderRadius: "8px",
		backgroundColor: "#f9f9f9",
		boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
		marginBottom: "1rem",
		width: window.innerWidth < 500 ? "250px" : "auto",
	},
	section: {
		marginBottom: "1rem",
	},
	sectionTitle: {
		fontSize: "1.2rem",
		fontWeight: "bold",
		color: "#333",
		marginBottom: "0.5rem",
	},
	item: {
		fontSize: "1rem",
		color: "#555",
		lineHeight: "1.5",
	},
};

const UserList: React.FC<{ setEdit: any }> = ({ setEdit }) => {
	const { users, permissions, texts } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleEdit = async (id: string) => {
		setEdit(id);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		try {
		} catch (error) {
		} finally {
		}
	}, []);

	console.log(users)

	return (
		<Box sx={{ mt: 4, mx: "auto", maxWidth: isMobile ? 320 : 1000 }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography variant="h5">{texts.userPage.user_list}</Typography>
			</Box>
			<TableContainer component={Paper} sx={{ borderRadius: 2, overflow: "auto" }}>
				<Table>
					<TableHead>
						<TableRow sx={{ bgcolor: theme.palette.primary.light }}>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>{texts.userPage.login}</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>{texts.userPage.password}</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>{texts.userPage.permissions}</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>{texts.userPage.action}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.length > 1 ? (
							users?.map((user: any) => (
								<TableRow key={user._id}>
									<TableCell style={permissionsStyle.sectionTitle}>{user.login}</TableCell>
									<TableCell style={permissionsStyle.sectionTitle}>{user.pass}</TableCell>
									<TableCell>
										{Object.entries(user.permissions).map(([key, value]) => {
											if (typeof value === "object" && !Array.isArray(value)) {
												return (
													<div key={key} style={permissionsStyle.container}>
														<p style={permissionsStyle.sectionTitle}>{texts.userPage[key]}</p>

														{Object.entries(value ? value : {}).map(([key1, value1]) => (
															<p key={key1} style={permissionsStyle.item}>
																<strong>{texts.userPage[key1]}:</strong> {value1 ? "✔️" : "❌"}
															</p>
														))}
													</div>
												);
											}
											return null;
										})}
									</TableCell>
									<TableCell>
										<IconButton onClick={() => handleEdit(user._id)}>
											<EditIcon sx={{ fontSize: "30px" }} />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow key={users[0]?._id}>
								<TableCell style={permissionsStyle.sectionTitle}>{users[0]?.login}</TableCell>
								<TableCell style={permissionsStyle.sectionTitle}>{users[0]?.pass}</TableCell>
								<TableCell>
									{Object.entries(users[0]?.permissions ?? {}).map(([key, value]) => {
										if (typeof value === "object" && !Array.isArray(value)) {
											return (
												<div key={key} style={permissionsStyle.container}>
													<p style={permissionsStyle.sectionTitle}>{texts.userPage[key]}</p>

													{Object.entries(value ? value : {}).map(([key1, value1]) => (
														<p key={key1} style={permissionsStyle.item}>
															<strong>{texts.userPage[key1]}:</strong> {value1 ? "✔️" : "❌"}
														</p>
													))}
												</div>
											);
										}
										return null;
									})}
								</TableCell>
								<TableCell>
									<IconButton onClick={() => handleEdit(users[0]?._id)}>
										<EditIcon sx={{ fontSize: "30px" }} />
									</IconButton>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default UserList;
