import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
//@ts-ignore
import logo from "../../logos/casa.png";
import { MainContext } from "../../MainContext";
import Swal from "sweetalert2";

 

const Navbar: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [pages, setPages] = useState<any[]>([])
	const { texts, switchLanguage, language, permissions, updateProperties } = useContext(MainContext);

	const all_pages = [
		{ name: texts.navbar.home, location: "/", key: "home" },
		{ name: texts.navbar.client, location: "/client", key: "client" },
		{ name: texts.navbar.employee, location: "/employee", key: "employee" },
		{ name: texts.userPage.user, location: "/user", key: "user" },
		{ name: texts.navbar.property, location: "/property", key: "property" },
	];

	const logOut = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "You want to log out?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				localStorage.removeItem("token");
				navigate("/login");
			}
		});
	};

	const isLoginPage = location.pathname === "/login";

	useEffect(() => {
        const newPages:any[] = [];
        Object.entries(permissions).map(([key, value]) => {
			//@ts-ignore
			if (value?.default) {
				newPages.push(all_pages.find((p) => p.key === key));	
			}
        });
        setPages(newPages);
    }, [permissions]);
	return (
		<AppBar position="sticky" sx={{ mb: 4, backgroundColor: "#000231" }}>
			<Toolbar>
				{/* Logo Section */}
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="h6" sx={{ color: "white" }}>
						<Link
							to="/"
							onClick={() => updateProperties(1)}
							style={{
								color: "white",
								textDecoration: "none",
								display: "flex",
								alignItems: "center",
								maxWidth: "120px",
							}}
						>
							<img style={{ width: "120px" }} src={logo} alt="Logo" />
						</Link>
					</Typography>
				</Box>

				{/* Navigation Buttons */}
				{!isLoginPage && (
					<>
						{pages?.map((page) => (
							<Button color="inherit" component={Link} to={page.location} sx={{ marginRight: 2 }}>
								{page.name}
							</Button>
						))}
						<Button color="inherit" onClick={logOut} sx={{ marginRight: 2 }}>
							Logout
						</Button>
					</>
				)}

				{/* Language Switch Button */}
				<Button
					color="inherit"
					onClick={() => switchLanguage(language === "en" ? "arm" : "en")}
					sx={{ marginLeft: 2 }}
				>
					{language === "en" ? "Hy" : "En"}
				</Button>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
