import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import {
	BrowserRouter,
	HashRouter,
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
	matchPath,
} from "react-router-dom";
import Navbar from "./components/Navbar/NavBar";
import PropertyPage from "./components/Property/PropertyPage";
import ClientPage from "./components/Client/Client";
import axios from "axios";
import { MainContext, MainContextProvider } from "./MainContext";
import ComponentPage from "./components/Property/ComponentPage";
import EditComponentPage from "./components/Property/EditComponentPage";
import EmployeePage from "./components/Employee/Employee";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MobileNavbar from "./components/Navbar/MobileNavbar";
import UserForm from "./components/User/User";
import Login from "./components/Login/Login";

const theme = createTheme({
	palette: {
		primary: {
			main: "#000231",
		},
		secondary: {
			main: "#000231",
		},
		background: {
			default: "#f5f5f5",
			paper: "#ffffff",
		},
		text: {
			primary: "#000000",
			secondary: "#757575",
		},
	},
});

// axios.defaults.baseURL = process.env.REACT_APP_BACK_URL + "/api/";

const Layout: React.FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [pages, setPages] = useState<any[]>([]);
	const { permissions, token } = useContext(MainContext);
	const [isPagesLoaded, setIsPagesLoaded] = useState(false); 

	const all_pages = [
		{ location: "/", key: "home" },
		{ location: "/client", key: "client" },
		{ location: "/employee", key: "employee" },
		{ location: "/user", key: "user" },
		{ location: "/property", key: "property" },
	];

	useEffect(() => {
		const newPages: any[] = [];
		Object.entries(permissions).forEach(([key, value]) => {
			//@ts-ignore
			if (value?.default) {
				const page = all_pages.find((p) => p.key === key);
				if (page) {
					newPages.push(page);
				}
			}
		});
		setPages(newPages);
		setIsPagesLoaded(true); 
	}, [permissions]);

	useEffect(() => {
		if (!localStorage.getItem("token") && pathname !== "/login") {
			localStorage.removeItem("token");
			navigate("/login");
			return;
		}

		if (!isPagesLoaded) return;
		
		const isAllowedPage = pages.some((page) => page.location === pathname);
		if (isAllowedPage) {
			return;
		} else if (pathname !== "/login" && !matchPath("/component/*", pathname)) {
			navigate("/");
		}
	}, [pathname, navigate, pages, isPagesLoaded]);

	return (
		<>
			{window.innerWidth < 500 ? <MobileNavbar /> : <Navbar />}
			<CssBaseline />
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<App />} />
				<Route path="/property" element={<PropertyPage />} />
				<Route path="/client" element={<ClientPage />} />
				<Route path="/employee" element={<EmployeePage />} />
				<Route path="/user" element={<UserForm />} />
				<Route path="/component/:id" element={<ComponentPage />} />
				<Route path="/component/edit/:id" element={<EditComponentPage />} />
			</Routes>
		</>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<ThemeProvider theme={theme}>
		<MainContextProvider>
			<HashRouter>
				<Layout />
			</HashRouter>
		</MainContextProvider>
	</ThemeProvider>
);
