import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "./MainContext";
import {
	Box,
	Typography,
	Collapse,
	TextField,
	Divider,
	Tooltip,
	useMediaQuery,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropertyFilter from "./components/Property/PropertyFilter/PropertyFilter";
import PropertyList from "./components/Property/PropertyList";
import Autocomplete from "@mui/material/Autocomplete";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { styled, useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import Modal from "@mui/material/Modal";
import { request } from "./request";
import HomePageMap from "./components/Property/HomePageMap";

let pervious_code = "";
interface CollapsableBoxProps {
	title: string;
	children: React.ReactNode; // Accept children as a prop
}
const drawerWidth = 440;

const style = {
	position: "absolute",
	top: "100%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	border: "none",
	boxShadow: 24,
	outline: "none",
	p: "20px",
	width: { xs: "330px", sm: "70%", md: "50%", lg: "40%" }, // Responsive width
	maxHeight: { xs: "70vh", md: "none" }, // Limit height on small screens
	overflowY: "auto", // Enable scrolling if content overflows
	borderRadius: 2, // Optional: rounded corners
	bgcolor: "background.paper", // Ensure background color adapts to theme
};

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-start",
}));

const App: React.FC = () => {
	const { properties, setFilteredProperties, filteredProperties, setProperties, codes, texts } =
		useContext(MainContext);
	const [code, setCode] = useState(null);
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [mapProperties, setMapProperties] = useState([]);
	const [openMap, setOpenMap] = useState(false);

	const updateMapProperties = async () => {
		const res = await request.get("/property/map");
		console.log(res.data);
		setMapProperties(res.data);
	};

	const updateProperties = async (code: string | null) => {
		if (code) {
			const response = await request.get(`/property/code?code=${code}`);
			setFilteredProperties(response.data.property);
		} else {
			setFilteredProperties(properties);
		}
	};

	console.log(mapProperties);
	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleMapOpen = () => {
		setOpenMap(true);
	};

	const handleMapClose = () => {
		setOpenMap(false);
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const pageWidth = window.innerWidth;
		if (event.clientX < pageWidth - 450) {
			setOpen(false);
		}
		console.log(event.clientX);
	};

	const options = useMemo(() => {
		return codes
			.map((code: any) => {
				if (code !== undefined) {
					return { label: code };
				}
			})
			.filter(Boolean);
	}, [properties]);

	const handleChange = (event: any, value: any) => {
		const selectedCode = value ? value.label : null;
		setCode(selectedCode); // Update local state
		updateProperties(selectedCode); // Update the filtered list
	};

	useEffect(() => {
		updateMapProperties();
	}, []);

	return (
		<div className="main" onClick={handleClick} style={{ height: "100%" }}>
			<Box sx={{ p: 4, position: "relative" }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Autocomplete
						disablePortal
						options={options}
						sx={{ width: "100%", pointerEvents: open ? "none" : "auto" }}
						onChange={handleChange}
						renderInput={(params) => (
							<TextField
								{...params}
								label={texts.maininput.code}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.preventDefault();
										const inputValue = params.inputProps.value;

										// Check for matching option
										const matchingOption = options.find((opt: any) => opt.label == inputValue);

										// Trigger handleChange with matching option or new value
										handleChange(null, matchingOption || inputValue);
									} else if (event.key === "Backspace") {
										const inputValue = params.inputProps.value;
										if (inputValue === "") {
											updateProperties(null);
										}
									}
								}}
							/>
						)}
					/>

					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Tooltip title="Open Filter">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="end"
								onClick={handleDrawerOpen}
								sx={[open && { display: "none" }, { marginLeft: "20px" }]}
							>
								<FilterAltIcon sx={{ fontSize: "35px" }} />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				{isMobile ? (
					<Drawer
						sx={{
							"& .MuiDrawer-paper": {
								width: "100%",
								height: "60%",
								borderRadius: "20px 20px 0 0",
								bottom: 0,
							},
						}}
						anchor="bottom"
						open={open}
						onClose={handleDrawerClose}
						variant="temporary"
					>
						<Divider />
						<List>
							<ListItem disablePadding>
								<Box>
									<PropertyFilter full={true} setFilteredProperties={setFilteredProperties} />
								</Box>
							</ListItem>
						</List>
					</Drawer>
				) : (
					<Drawer
						sx={{
							width: drawerWidth,
							flexShrink: 0,
							"& .MuiDrawer-paper": {
								width: drawerWidth,
							},
						}}
						variant="persistent"
						anchor="right"
						open={open}
					>
						<DrawerHeader>
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<List>
							<ListItem disablePadding>
								<PropertyFilter full={true} setFilteredProperties={setFilteredProperties} />
							</ListItem>
						</List>
					</Drawer>
				)}
				<Box sx={{ marginTop: "50px", pointerEvents: open ? "none" : "auto" }}>
					<Accordion
						sx={{
							maxWidth: "100%",
							mx: "auto",
							"&.Mui-expanded": {
								marginLeft: "auto",
								marginRight: "auto",
							},
							"&.Mui-expanded:first-of-type": {
								marginLeft: "auto",
								marginRight: "auto",
								marginTop: "30px",
							},
							"&:first-of-type": {
								marginTop: "30px",
							},
							"&:before": {
								display: "none",
							},
							"@media (max-width:600px)": {
								width: "100%",
							},
							borderRadius: "10px",
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{
								bgcolor: theme.palette.primary.light,
								borderRadius: "10px",
							}}
							onClick={handleMapOpen}
						>
							<Typography component="span" sx={{ color: "white" }}>
								{texts.propertyPage.map}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<HomePageMap
								properties={mapProperties}
								onChange={(location) => console.log("Selected:", location)}
							/>
						</AccordionDetails>
					</Accordion>
					<Divider style={{ margin: "30px 0" }}>{texts.propertyList.property_list}</Divider>
					<PropertyList />
				</Box>
			</Box>
		</div>
	);
};

export default App;
