import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useMediaQuery,
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { request } from "../../request";

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
	tg_username: string;
}

const AddClient = () => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState("");
	const [formData, setFormData] = useState<IFormData>({
		name: "",
		contact: [],
		comment: "",
		tg_username: "@",
	});
	const { employeeTypes, updateEmployeeTypes, updateEmployee, texts } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await request.post("/employee", formData); // Adjust endpoint as needed
			console.log("Agent created:", response.data);
			setFormData({
				name: "",
				contact: [],
				comment: "",
				tg_username: "@",
			});
			updateEmployee();
		} catch (error) {
			console.error("Error creating agent:", error);
			alert("Failed to create agent.");
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...values, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};
	console.log(formData);
	const handleDelete = (valueToDelete: string) => {
		const updatedValues = values.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	return (
		<>
			<Accordion
				sx={{
					maxWidth: isMobile ? 320 : 400,
					mx: "auto",
					"&.Mui-expanded": {
						marginLeft: "auto",
						marginRight: "auto",
					},
					"&.Mui-expanded:first-of-type": {
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: "30px",
					},
					"&:first-of-type": {
						marginTop: "30px",
					},
					"&:before": {
						display: "none",
					},
					"@media (max-width:600px)": {
						width: "100%",
					},
					borderRadius: "10px",
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
					aria-controls="panel1-content"
					id="panel1-header"
					sx={{
						bgcolor: theme.palette.primary.light,
						borderRadius: "10px",
					}}
				>
					<Typography component="span" sx={{ color: "white" }}>
						{texts.clientPage.add_employee}
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{maxWidth: isMobile ? 320 : 1300, mx: "auto"}}>
					<Box
						component="form"
						sx={{
							maxWidth: isMobile ? 320 : 400,
							mx: "auto",
							mt: 4,
							p: 4,
							borderRadius: 2,
						}}
					>
						<Typography variant="h5" gutterBottom>
							{texts.clientPage.add_employee}
						</Typography>
						<TextField
							label={texts.clientPage.name}
							name="name"
							value={formData.name}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
						/>
						<Box sx={{ marginTop: "10px" }}>
							<TextField
								label={texts.clientPage.contact}
								variant="outlined"
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={handleKeyDown}
								fullWidth
							/>
							<Box mt={2}>
								{values.map((value) => (
									<Chip
										key={value}
										label={value}
										onDelete={() => handleDelete(value)}
										style={{ margin: "5px" }}
									/>
								))}
							</Box>
						</Box>
						<TextField
							label={texts.clientPage.comment}
							name="comment"
							value={formData.comment}
							onChange={handleChange}
							fullWidth
							multiline
							margin="normal"
						/>
						<TextField
							label="TG username"
							name="tg_username"
							value={formData.tg_username}
							onChange={handleChange}
							fullWidth
							multiline
							margin="normal"
						/>

						<Button
							type="button"
							onClick={handleSubmit}
							variant="contained"
							color="primary"
							fullWidth
							sx={{ mt: 2 }}
						>
							{texts.clientPage.create_employee}
						</Button>
					</Box>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

export default AddClient;
