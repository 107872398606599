import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ILocation } from "../../../interfaces/location";

interface YandexMapProps {
    onChange: (location: ILocation) => unknown;
}
declare global {
    interface Window {
        ymaps: any;
    }
}

const FilterMap: React.FC<YandexMapProps> = ({ onChange }) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<any>(null);
    const [address, setAddress] = useState<string>("_");
    const currentPlacemarkRef = useRef<any>(null); // Reference to the current placemark
    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Reverse Geocoding to get city, district, street, building number
    const handleMapClick = async (coords: [number, number]) => {
        const [latitude, longitude] = coords;

        // Remove the current placemark if it exists
        if (currentPlacemarkRef.current) {
            mapRef.current?.geoObjects.remove(currentPlacemarkRef.current);
            currentPlacemarkRef.current = null;
        }

        // Remove all existing placemarks
        // mapRef.current?.geoObjects.removeAll();

        // Create a new placemark at the clicked location
        const placemark = new window.ymaps.Placemark(coords, {
            balloonContent: "You clicked here!",
        });

        // Add the placemark to the map
        mapRef.current?.geoObjects.add(placemark);
        currentPlacemarkRef.current = placemark;
        // Perform reverse geocoding
        try {
            const res = await window.ymaps.geocode([latitude, longitude]);
            const firstGeoObject = res.geoObjects.get(0);

            if (firstGeoObject) {
                // Extract location details from the geocode result
                const geoProps = firstGeoObject.properties.get("metaDataProperty").GeocoderMetaData.Address;
                const components = geoProps.Components;

                // Helper function to get a specific component by type
                const getComponent = (type: string) => components.find((component: any) => component.kind === type)?.name || "";

                const location: ILocation = {
                    country: getComponent("country"),
                    region: getComponent("province"),
                    city: getComponent("locality"),
                    district: getComponent("district"),
                    street: getComponent("street"),
                    building: getComponent("house"),
                    lat: latitude,
                    lng: longitude,
                };

                setAddress(firstGeoObject.getAddressLine());
                onChange(location); // Pass the location object to the callback
            }
        } catch (err) {
            console.error("Error getting address:", err);
        }
    };

    useEffect(() => {
        // Initialize the map after the component is mounted
        const initMap:() => any = () => {
            try {
                if (mapContainerRef.current) {
                    mapRef.current = new window.ymaps.Map(mapContainerRef.current, {
                        center: [40.17, 44.51], // Center of the map
                        zoom: 12, // Zoom level
                    });

                    // Event listener for map click to drop a point
                    mapRef.current.events.add("click", (e: any) => {
                        const coords = e.get("coords"); // Get clicked coordinates [lat, lon]

                        handleMapClick(coords);
                    });
                }
            } catch {
                setTimeout(initMap, 500);
            }
        };

        initMap();

        return () => {
            // Clean up the map when component unmounts
            if (mapRef.current) {
                mapRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className="map">
            <p style={{ marginBottom: "10px" }}>{address}</p>
            <div ref={mapContainerRef} style={{ width: "100%", height: "500px", padding: isMobile ? "40px" : "" }} />
        </div>
    );
};

export default FilterMap;
