import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button, Typography, Box, IconButton } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
//@ts-ignore
import logo from "../../logos/casa.png";
import { MainContext } from "../../MainContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";


const MobileNavbar: React.FC = () => {
    const navigate = useNavigate();
	const location = useLocation();
	const [pages, setPages] = useState<any[]>([])
	const { texts, switchLanguage, language, permissions } = useContext(MainContext);
    const [anchorElForMenu, setAnchorElForMenu] = useState(null);
    
	const all_pages = [
		{ name: texts.navbar.home, location: "/", key: "home" },
		{ name: texts.navbar.client, location: "/client", key: "client" },
		{ name: texts.navbar.employee, location: "/employee", key: "employee" },
		{ name: texts.userPage.user, location: "/user", key: "user" },
		{ name: texts.navbar.property, location: "/property", key: "property" },
	];

	const openMenu = Boolean(anchorElForMenu);
	const handleClickMenu = (event: any) => {
		setAnchorElForMenu(event.currentTarget);
	};
	const handleCloseForMenu = () => {
		setAnchorElForMenu(null);
	};

	const handleNavigate = (page_location: any) => {
		if (location.pathname !== page_location) {
			navigate(page_location);
		}
		setAnchorElForMenu(null);
	};

	const logOut = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "You want to log out?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				localStorage.removeItem("token");
				navigate("/login");
			}
		});
	};

	useEffect(() => {
        const newPages:any[] = [];
        Object.entries(permissions).map(([key, value]) => {
			//@ts-ignore
			if (value?.default) {
				newPages.push(all_pages.find((p) => p.key === key));	
			}
        });
        setPages(newPages);
    }, [permissions]);
	return (
		<AppBar position="sticky" sx={{ mb: 4, backgroundColor: "#000231" }}>
			<Toolbar>
				{/* Logo Section */}
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="h6" sx={{ color: "white" }}>
						<Link
							to="/"
							style={{
								color: "white",
								textDecoration: "none",
								display: "flex",
								alignItems: "center",
								maxWidth: "120px",
							}}
						>
							<img style={{ width: "120px" }} src={logo} alt="Logo" />
						</Link>
					</Typography>
				</Box>

				<div>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-haspopup="true"
						onClick={handleClickMenu}
						sx={{ color: "white" }}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							"aria-labelledby": "long-button",
						}}
						anchorEl={anchorElForMenu}
						open={openMenu}
						onClose={handleCloseForMenu}
						slotProps={{
							paper: {
								style: {
									maxHeight: 48 * 4.5,
									width: "20ch",
								},
							},
						}}
					>
						{pages.map((option) => (
							<MenuItem
								key={option.location}
								selected={option.key === "Pyxis"}
								onClick={() => handleNavigate(option.location)}
							>
								{option.name}
							</MenuItem>
						))}
						<MenuItem
							key="logout"
                            onClick={() => switchLanguage(language === "en" ? "arm" : "en")}
						>
							{language === "en" ? "Hy" : "En"}
						</MenuItem>
						<MenuItem
							key="logout"
                            onClick={logOut}
						>
							Log out
						</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default MobileNavbar;
