import {
	Box,
	FormControlLabel,
	FormControl,
	FormLabel,
	Checkbox,
	Divider,
	useMediaQuery,
	useTheme
} from "@mui/material";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../../MainContext";

const FilterMainInput: React.FC<{ control: any }> = ({ control }) => {
	const { keys, texts } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box sx={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px" }}>
			<Divider sx={{ margin: "30px 0", fontSize: "1.2rem", fontWeight: "bold", color: "#444" }}>
				Main Information
			</Divider>
			<Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
				<FormControl
					sx={{
						padding: "15px",
						border: "1px solid #ccc",
						borderRadius: "8px",
						backgroundColor: "white",
						boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
						minWidth: "150px",
						flex: "1 1 auto",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend" sx={{ fontWeight: "bold", color: "#333" }}>
						{texts.watch.action}
					</FormLabel>
					<Controller
						name="action"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["sell", "rent"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												checked={value?.includes(option as "sell" | "rent") || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option]
														: value.filter((item: any) => item !== option);
													onChange(newValue);
												}}
											/>
										}
										label={texts.watch[option]}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "15px",
						border: "1px solid #ccc",
						borderRadius: "8px",
						backgroundColor: "white",
						boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
						minWidth: "200px",
						flex: "1 1 auto",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend" sx={{ fontWeight: "bold", color: "#333" }}>
						{texts.watch.type}
					</FormLabel>
					<Controller
						name="type"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["apartment", "house", "commercial"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												checked={value?.includes(option) || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option]
														: value.filter((item: any) => item !== option);
													onChange(newValue);
												}}
											/>
										}
										label={texts.watch[option]}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "15px",
						border: "1px solid #ccc",
						borderRadius: "8px",
						backgroundColor: "white",
						boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
						minWidth: isMobile ? "150px" : '300px',
						flex: "1 1 auto",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend" sx={{ fontWeight: "bold", color: "#333" }}>
						{texts.watch.status}
					</FormLabel>
					<Controller
						name="status"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								{["pre-owned", "new"].map((option) => (
									<FormControlLabel
										key={option}
										control={
											<Checkbox
												checked={value?.includes(option) || false}
												onChange={(e) => {
													const newValue = e.target.checked
														? [...(value || []), option]
														: value.filter((item: any) => item !== option);
													onChange(newValue);
												}}
											/>
										}
										label={texts.watch[option === "pre-owned" ? "preOwned" : option]}
									/>
								))}
							</>
						)}
					/>
				</FormControl>
				<FormControl
					sx={{
						padding: "15px",
						border: "1px solid #ccc",
						borderRadius: "8px",
						backgroundColor: "white",
						boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
						minWidth: isMobile ? "150px" : '300px',
						flex: "1 1 auto",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend" sx={{ fontWeight: "bold", color: "#333" }}>
						{texts.watch.buildingType}
					</FormLabel>
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
						<Controller
							name="buildingType"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									{["Stone", "Panels", "Monolith", "Bricks", "Cassette", "Wooden"].map((option) => (
										<FormControlLabel
											key={option}
											control={
												<Checkbox
													checked={value?.includes(option) || false}
													onChange={(e) => {
														const newValue = e.target.checked
															? [...(value || []), option]
															: value.filter((item: any) => item !== option);
														onChange(newValue);
													}}
												/>
											}
											label={texts.watch[option]}
										/>
									))}
								</>
							)}
						/>
					</Box>
				</FormControl>
				<FormControl
					sx={{
						padding: "15px",
						border: "1px solid #ccc",
						borderRadius: "8px",
						backgroundColor: "white",
						boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
						minWidth: isMobile ? "150px" : '300px',
						flex: "1 1 auto",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
					component="fieldset"
					margin="normal"
				>
					<FormLabel component="legend" sx={{ fontWeight: "bold", color: "#333" }}>
						Checkboxes
					</FormLabel>
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
						<Controller
							name="other"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									{keys
										?.filter((k: any) => k.type === "checkbox")
										//@ts-ignore
										.map(({ name: option }) => (
											<FormControlLabel
												key={option}
												control={
													<Checkbox
														checked={value?.includes(option) || false}
														onChange={(e) => {
															const newValue = e.target.checked
																? [...(value || []), option]
																: value.filter((item: any) => item !== option);
															onChange(newValue);
														}}
													/>
												}
												label={option.charAt(0).toUpperCase() + option.slice(1)}
											/>
										))}
								</>
							)}
						/>
					</Box>
				</FormControl>
			</Box>
		</Box>
	);
};

export default FilterMainInput;
