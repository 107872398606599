import React, { useContext, useEffect, useRef, useState } from "react";
import { ILocation } from "../../interfaces/location";
import { useNavigate } from "react-router-dom";
import { request } from "../../request";
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Grid,
	PaginationItem,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { MainContext } from "../../MainContext";

interface YandexMapProps {
	onChange: (location: ILocation) => unknown;
	properties: any[]; // Array of property locations
}

declare global {
	interface Window {
		ymaps: any;
	}
}

interface Map {
	id: any;
	lng: any;
	lat: any;
}

const HomePageMap: React.FC<YandexMapProps> = ({ onChange, properties }) => {
	const navigate = useNavigate();
	const mapContainerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<any>(null);
	const currentPlacemarkRef = useRef<any>(null);
	const [showBalloon, setShowBalloon] = useState(false);
    const [propertyId, setPropertyId] = useState("");
    const {texts} = useContext(MainContext)

	// const updatePropertyMap = async (id:any, lng:any, lat:any) => {
	//     setPlaceMarks((prev) => {
	//         if (prev.some((placemark) => placemark.id === id)) {
	//             return prev;
	//         }
	//         return [...prev, { id: id, lng: lng, lat: lat }];
	//     });
	// }

	console.log(properties);

	const addPlacemark = (
		coords: [number, number],
		id: string,
		price: any,
		currency: any,
		content: any
	) => {
		const [lat, lng] = coords;
		const placemark = new window.ymaps.Placemark(
			coords,
			{
				hintContent: content,
				iconContent: `${price} ${currency}`,
			},
			{
                preset: "islands#blueStretchyIcon",
                iconContentLayout: window.ymaps.templateLayoutFactory.createClass(
                    `<div style="font-size: 11px; color: #000;">$[properties.iconContent]</div>`
                ),
			}
		);
        mapRef.current?.geoObjects.add(placemark);
		placemark.events.add("click", (e: any) => {
			e.preventDefault();
            setPropertyId(id);
            setShowBalloon(true)
		});
    };
    
    
    const goToComponent = (id: string) => {
		navigate(`/component/${id}`);
	};


	useEffect(() => {
		const initMap = () => {
			if (!mapContainerRef.current) return;

			window?.ymaps?.ready(() => {
				// ✅ Ensure API is loaded before using `ymaps.Map`
				mapRef.current = new window.ymaps.Map(mapContainerRef.current, {
					center: [40.17, 44.51],
					zoom: 12,
				});

				if (mapRef.current) {
					mapRef.current.options.set("suppressMapOpenBlock", true); // Suppress unnecessary requests
					mapRef.current?.controls.remove("geolocationControl");
				}

				// Add placemarks for all properties
				properties.forEach((property) => {
					if (property.location.lat && property.location.lng) {
						addPlacemark(
							[property.location.lat, property.location.lng],
							property._id,
							property.price,
							property.currency,
							`${property.location.city} - ${property.location.street} - Code: ${property.code}`
						);
					}
				});
			});
		};

		initMap();

		return () => {
			if (mapRef.current) {
				mapRef.current.destroy();
			}
		};
	}, [properties]); // Re-run when properties change

	return (
		<div className="map">
			{showBalloon && (
				<div
					id="customBalloon"
					style={{
						position: "absolute",
						top: "150px",
						zIndex: "100",
						left: "10px",
						width: "300px",
                        padding: "10px",
                        height: "300px",
                        overflowY: "auto", 
                        overflowX: "hidden",
					}}
				>
					<Card
						onClick={() => goToComponent(propertyId)}
						sx={{ "&:hover": { cursor: "pointer" } }}
						className="property-card"
					>
						{properties.find((e) => e._id === propertyId).images[0] && (
							<CardMedia
								component="img"
								height="200px"
								sx={{ height: "20vh" }}
								image={process.env.REACT_APP_BACK_URL + "/api/" + "uploads/" + properties.find((e) => e._id === propertyId).images[0]}
								alt={properties.find((e) => e._id === propertyId).description}
							/>
						)}
						<CardContent sx={{backgroundColor: "#F0EFE7"}}>
							<Typography variant="body2" color="text.secondary" sx={{ fontWeight: "bold" }}>
								{texts.maininput.code}: {properties.find((e) => e._id === propertyId).code}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{texts.propertyList.price}: {properties.find((e) => e._id === propertyId).price} {properties.find((e) => e._id === propertyId).currency}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
								{texts.descriptionInput.description}: {properties.find((e) => e._id === propertyId).description}
							</Typography>
						</CardContent>
					</Card>
				</div>
			)}
			<div ref={mapContainerRef} style={{ width: "100%", height: "500px" }} />
		</div>
	);
};

export default HomePageMap;
