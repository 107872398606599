import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { MainContext } from "../../MainContext";
import PreferencesMainInputs from "./PreferncesMainInputs";
import PreferencesNumberInputs from "./PreferencesNumberInputs";



const ClientPreferences: React.FC<{ control: any, setValue: any, watch:any }> = ({
    control,
	setValue,
	watch
}) => {
	const { properties, setProperties, updateProperties } = useContext(MainContext);

	

	return (
		<Box component="form" sx={{ }}>

            <PreferencesMainInputs control={control} setValue={setValue} />

			<PreferencesNumberInputs control={control} setValue={setValue} watch={watch}/>

		</Box>
	);
};

export default ClientPreferences;
