import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { MainContext } from "../../MainContext";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

interface IClient {
	_id: string;
	name: string;
	contact: string;
	comment: string;
	type: string;
	associations: string[];
}

const OwnerList: React.FC<{ setNextToOwners: any }> = ({ setNextToOwners }) => {
	const { clients } =
		useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


	return (
		<Box sx={{ mt: 4, mx: "auto", maxWidth: isMobile ? 320 : 800 }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography variant="h5">Owner List</Typography>
				<IconButton onClick={() => setNextToOwners(false)} sx={{marginLeft: "10px"}}>
					<SkipPreviousIcon sx={{ fontSize: "34px" }} />
				</IconButton>
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Contact</TableCell>
							<TableCell>Comment</TableCell>
							<TableCell>Type</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{clients
							.map((client: any) => (
								<TableRow key={client._id}>
									<TableCell>{client.name}</TableCell>
									<TableCell>
										<ul style={{ margin: 0, paddingLeft: "20px" }}>
											{client.contact.map((contact: any, index: any) => (
												<li key={index}>{contact}</li>
											))}
										</ul>
									</TableCell>
                                    <TableCell>{client.comment}</TableCell>
                                    <TableCell>{client.type}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default OwnerList;
