import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormLabel,
	Select,
	MenuItem,
	Divider,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";
import { FormInputs } from "../../interfaces/formInputs";
import { MainContext } from "../../MainContext";
import Swal from "sweetalert2";
import MainInput from "./PropertyInput/MainInput";
import LocationInput from "./LocationInput";
import NumbersInputs from "./PropertyInput/NumbersInput";
import PropertiesInput from "./PropertyInput/PropertiesInput";
import DescriptionsInput from "./PropertyInput/DescriptionsInput";
import UploadInput from "./PropertyInput/UploadInput";
import OwnerInput from "./PropertyInput/OwnerInput";
import BuyerInput from "./PropertyInput/BuyerInput";
import EmployeeInput from "./PropertyInput/EmployeeInput";
import { useParams } from "react-router-dom";
import { IProperty } from "../../interfaces/property";
import { useNavigate } from "react-router-dom";
import { request } from "../../request";


const EditComponentPage: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [property, setProperty] = useState<IProperty>();
	const [imagePreviews, setImagePreviews] = useState<string[]>([]);
	const [imageFiles, setImageFiles] = useState<File[]>([]);
	const [imagePreviews_doc, setImagePreviews_doc] = useState<string[]>([]);
	const [imageFiles_doc, setImageFiles_doc] = useState<File[]>([]);
	const [deletedImages, setDeletedImages] = useState<string[]>([])
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		getValues,
		control,
		watch,
	} = useForm<FormInputs>({
		defaultValues: {
			action: property ? property.action : "sell",
			type: property ? property.type : "house",
			status: property ? property.status : "new",
			buildingType: property ? property.buildingType : "Bricks",
			renovation: property ? property.renovation : 0,
			location: {
				country: property ? property.location.country : "",
				region: property ? property.location.region : "",
				city: property ? property.location.city : "",
				district: property ? property.location.city : "",
				street: property ? property.location.street : "",
				building: property ? property.location.building : "",
				lat: property ? property.location.lat : 0,
				lng: property ? property.location.lng : 0,
				apartment: property ? property.location.apartment : "",
			},
			owner: {
				name: property ? property.owner.name : "",
				contact: property ? property.owner.contact : "",
				comment: property ? property.owner.comment : "",
			},
			buyer: property ? property.buyer : "",
			agent: property ? property.agent : ""
		},
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { keys, newCode } = useContext(MainContext);

	const watched = watch();

	const getProperty = async () => {
		try {
			const response = await request.get("/property/" + id);
			setProperty(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (id) getProperty();
	}, [id]);

	useEffect(() => {
		if (property) {
			setImagePreviews(property.images?.map((url) => process.env.REACT_APP_BACK_URL + "/api" + '/uploads/' + url) || []);
		}
	}, [property]);


	useEffect(() => {
		if (property) {
			const dynamicDefaults = property.other
			? Object.fromEntries(
					Object.entries(property.other).map(([key, value]) => [
						`${key}`,
						!!value,
					])
			  )
			: {};
			console.log(property.owner)
			const newValues = {
				...property,
				description: property.description || "",
				price: property.price || 0,
				other: dynamicDefaults,
				documents: property.documents || [],
				currency: property.currency || "",
				location: property.location || {
					country: "",
					region: "",
					city: "",
					district: "",
					street: "",
					building: "",
					lat: 0,
					lng: 0,
					apartment: "",
				},
				action: property.action || undefined,
				status: property.status || undefined,
				buildingType: property.buildingType || undefined,
				surface: property.surface || 0,
				ceiling: property.ceiling || 0,
				rooms: property.rooms || 0,
				bathrooms: property.bathrooms || 0,
				floor: property.floor || 0,
				totalFloors: property.totalFloors || 0,
				renovation: property.renovation || 0,
				owner: {
					name: property.owner.name,
					contact: property.owner.contact,
					comment: property.owner.comment,
				},
				buyer: property.buyer || "",
				agent: property.agent || "",
			};

			console.log(newValues);
			reset(newValues);
		}
	}, [property, reset]);

	console.log(watch())
	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		console.log(data);
		try {
			setLoading(true);
			const formData = new FormData();
			Object.entries(data).forEach(([key, value]) => {
				if (value) {
					if (typeof value === "object") {
						formData.append(key, JSON.stringify(value));
					} else {
						formData.append(key, value);
					}
				}
			});

			formData.append("remainingImages", JSON.stringify(deletedImages));
			formData.append("filter_doc", JSON.stringify(imageFiles_doc.map((file) => file.name)));
			console.log(imageFiles, imageFiles_doc);
			imageFiles.forEach((file) => formData.append("images", file));
			imageFiles_doc.forEach((file) => formData.append("images", file));
			console.log("formData", formData);
			await request.post("/property/update/" + id, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			Swal.fire({
				title: "Property updated successfully",
				icon: "success",
			});
			navigate("/component/" + id);
			// reset();
			// setImageFiles([]);
			// setImagePreviews([]);
		} catch (error) {
			console.error("Error uploading property:", error);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: `${error}`,
			});
		} finally {
			setLoading(false);
		}
	};

	const onError = (errors: any) => {
		const errorMessages = Object.entries(errors)
			//@ts-ignore
			.map(([field, error]) => `${field}: ${error.message}`)
			.join("\n");

		Swal.fire({
			icon: "error",
			title: "Validation Errors",
			text: "Please fix the following errors before submitting:\n" + errorMessages,
			customClass: {
				container: "swal-error-container",
			},
		});
	};
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		console.log(files)
		if (files) {
			const selectedFiles = Array.from(files);
			setImageFiles([...imageFiles, ...selectedFiles]);
			setImagePreviews([
				...imagePreviews,
				...selectedFiles.map((file) => URL.createObjectURL(file)),
			]);
		}
	};

	const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const selectedFiles = Array.from(files);
			setImageFiles_doc([...imageFiles_doc, ...selectedFiles]);
			setImagePreviews_doc([...imagePreviews_doc, ...selectedFiles.map((file) => file.name)]);
		}
	};

	const handleRemoveImage = (index: number, src: string) => {
		setImageFiles((prev) => prev.filter((img, i) => img.name !== src));
		setImagePreviews((prev) => prev.filter((img, i) => img.split("/").pop() !== src));
		imagePreviews.forEach((e, i) => {
			if (e.split("/").pop() === src) {
				const deleted_imagee = e.split("/").pop()!
				setDeletedImages([...deletedImages, deleted_imagee])
			}
		})
	};
	console.log(deletedImages)

	return (
		<Box component="form" sx={{ p: 3 }} onSubmit={handleSubmit(onSubmit, onError)}>
			<MainInput control={control} setValue={setValue} />

			<Divider style={{ margin: "30px 0" }}>Location</Divider>
			{/* <LocationInput {...register("location", { required: true })} /> */}

			<NumbersInputs control={control} setValue={setValue} watch={watch} />

			<PropertiesInput register={register} control={control} />

			<DescriptionsInput register={register} control={control} />

			<br />

			<UploadInput
				imagePreviews={imagePreviews}
				handleImageChange={handleImageChange}
				handleRemoveImage={handleRemoveImage}
				handleDocumentChange={handleDocumentChange}
				imagePreviews_doc={imagePreviews_doc}
			/>
            <Box sx={{ display: isMobile ? "inline" : "flex" }}>
				<OwnerInput {...register("owner", { required: false })} watch={watch} setValue={setValue} />
                <BuyerInput {...register("buyer", { required: false })} setValue={setValue} watch={watch} />
            </Box>

            <EmployeeInput {...register("agent", { required: false })} setValue={setValue} watch={watch}/>


			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={loading}
				sx={{ marginTop: "40px" }}
			>
				{loading ? <CircularProgress size={24} /> : "Update Property"}
			</Button>
		</Box>
	);
};

export default EditComponentPage;
