import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import Premissions from "./Permissions";
import { request } from "../../request";
import EmployeeFiled from "./EmployeeField";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const AddUser = () => {
	const [login, setLogin] = useState("");
	const [pass, setPass] = useState("");
	const [values, setValues] = useState();
	const [employee, setEmployee] = useState("")
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const {updateUsers, texts} = useContext(MainContext)

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await request.post("/user/register", {
				login: login,
				pass: pass,
				owner: employee,
				permissions: values,
			}); // Adjust endpoint as needed
			Swal.fire({
				title: "User added successfully",
				icon: "success",
			});
			updateUsers()
		} catch (error) {

		}
	};

	const getPermissions = async () => {
		const response = await request.get("/user/permissions");
		setValues(response.data);
	};

	useEffect(() => {
		getPermissions();
	}, []);

	console.log(employee)

	return (
		<>
			<Box
				component="form"
				sx={{
					maxWidth: isMobile ? 320 : 1300,
					mx: "auto",
					mt: 4,
					p: 4,
					border: "1px solid #ccc",
					borderRadius: 2,
				}}
			>
				<Typography variant="h5" gutterBottom>
					{texts.userPage.add_user}
				</Typography>
				<TextField
					label={texts.userPage.login}
					name={texts.userPage.login}
					value={login}
					fullWidth
					required
					margin="normal"
					onChange={(e) => setLogin(e.target.value)}
				/>
				<TextField
					label={texts.userPage.password}
					name={texts.userPage.password}
					value={pass}
					fullWidth
					multiline
					margin="normal"
					onChange={(e) => setPass(e.target.value)}
				/>
				<EmployeeFiled employee={employee} setEmployee={setEmployee} />
				<Premissions values={values} setValues={setValues} />
				<Button
					type="button"
					variant="contained"
					color="primary"
					fullWidth
					sx={{ mt: 2 }}
					onClick={handleSubmit}
				>
					{texts.userPage.create_user}
				</Button>
			</Box>
		</>
	);
};

export default AddUser;
