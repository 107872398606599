import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Divider, useMediaQuery, useTheme } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { MainContext } from "../../../MainContext";
import FilterMainInput from "./FilterMainInput";
import FilterNumbersInput from "./FilterNumbersInput";
import { ILocation } from "../../../interfaces/location";
import { request } from "../../../request";
import LocationInput from "../LocationInput";
import FilterLocationInput from "./FilterLocationInput";



interface FormInputs {
	description: string;
	price: number;
	action: Array<"rent" | "sell">;
	type: Array<"apartment" | "house" | "commercial">;
	buildingType: Array<"Stone" | "Panels" | "Monolith" | "Bricks" | "Cassette" | "Wooden">;
	status: Array<"pre-owned" | "new">;
	propertyStatus: Array<"active" | "stopped" | "sold">;
	surface: {
		from: number;
		to: number;
	};
	rooms: {
		from: number;
		to: number;
	};
	images: FileList;
	location: ILocation;
	currency: string;
	bathrooms: {
		from: number;
		to: number;
	};
	ceiling: {
		from: number;
		to: number;
	};
	renovation: {
		from: number;
		to: number;
	};
	floor: {
		from: number;
		to: number;
	};
	totalFloors: {
		from: number;
		to: number;
	};
	other: any;
}


const PropertyFilter: React.FC<{ full: boolean; setFilteredProperties: any }> = ({
	full,
	setFilteredProperties,
}) => {
	const { properties, setProperties, updateProperties, updateFilters } = useContext(MainContext);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		getValues,
		control,
		watch,
	} = useForm<FormInputs>({
		defaultValues: {
			action: [],
			type: [],
			buildingType: [],
			status: [],
			propertyStatus: [],
		},
	});


	const watched = watch();
	console.log(watched)

	const removeFilters = () => {
		reset();
	};


	useEffect(() => {
		try {
			const fetchData = async () => {
				try {
					const formData = new FormData();
					Object.entries(watched).forEach(([key, value]) => {
						if (value) {
							if (key === "other" && Array.isArray(value)) {
								if (value.length > 0) {
									const otherObj: Record<string, boolean> = {};
									value.forEach((item) => {
										otherObj[item] = true;
									});
									formData.append(key, JSON.stringify(otherObj));
								} else {
									formData.append(key, "{}");
								}
							}else if (typeof value === "object") {
								formData.append(key, JSON.stringify(value));
							} else {
								formData.append(key, value);
							}
						}
					});
					console.log(formData)
					updateFilters(formData)
				} catch (error) {
					console.error("Error submitting form:", error);
				}
			};
		
			fetchData();
		} catch (error) {
			
		}

	}, [JSON.stringify(watched)]);

	return (
		<Box component="form" sx={{ p: isMobile ? 0 : 3, width: isMobile ? "100%" : "440px", overflow: 'hidden' }}>

			<FilterMainInput control={control} />

			<Divider style={{ margin: "30px 0" }}>Location</Divider>
			<FilterLocationInput {...register("location", { required: true })} />

			<FilterNumbersInput control={control} />

			<Button
				sx={{
					width: isMobile ? "90%" : 400,
					marginLeft: isMobile ? "20px" : ""
				}}
				variant="contained"
				color="primary"
				disabled={loading}
				onClick={() => removeFilters()}
			>
				{loading ? <CircularProgress size={24} /> : "Remove Filters"}
			</Button>
		</Box>
	);
};

export default PropertyFilter;
