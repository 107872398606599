import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	useMediaQuery,
	useTheme,
	Tooltip,
	Collapse,
	Stack,
	PaginationItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Pagination from "@mui/material/Pagination";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MainContext } from "../../MainContext";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Swal from "sweetalert2";
import { request } from "../../request";

interface IClient {
	_id: string;
	name: string;
	contact: string[];
	comment: string;
	owner: string;
	action: string;
	type: string;
	status: string;
	buildingType: string;
	surface: {
		from: number;
		to: number;
	};
	ceiling: {
		from: number;
		to: number;
	};
	rooms: {
		from: number;
		to: number;
	};
	bathrooms: {
		from: number;
		to: number;
	};
	floor: {
		from: number;
		to: number;
	};
	totalFloors: {
		from: number;
		to: number;
	};
	renovation: {
		from: number;
		to: number;
	};
	clientStatus: string;
}

const ClientList: React.FC<{ setEdit: any; setNextToOwners: any }> = ({
	setEdit,
	setNextToOwners,
}) => {
	const { clients, permissions, employees, users, texts } = useContext(MainContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [slicedClients, setSlicedClients] = useState([])

	// Store expanded row states
	const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

	// Toggle row expansion
	const toggleRow = (id: string) => {
		setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	useEffect(() => {
		setSlicedClients(clients.slice(((page - 1) * 6), 6))
		setPageCount(Math.ceil(clients.length / 6));
	}, [clients]);

	// Handle client edit
	const handleEdit = (client: IClient) => {
		if (
			permissions.client.edit_client ||
			(permissions.client.edit_own_client && users.owner === client.owner)
		) {
			setEdit(client._id);
			window.scrollTo(0, 0);
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You don't have permission to edit this client.",
			});
		}
	};

	const handleUpdate = (client: IClient) => {
		if (
			permissions.client.edit_client ||
			(permissions.client.edit_own_client && users.owner === client.owner)
		) {
			Swal.fire({
				title: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Update status",
			}).then(async (result) => {
				if (result.isConfirmed) {
					await request.post("/client/update-status/" + client._id);
					Swal.fire({
						title: "Client status updated",
						icon: "success",
					});
				}
			});
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You don't have permission to edit this client.",
			});
		}
	};

	const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
		window.scrollTo(0, 0)
		setPage(value)
		setSlicedClients(clients.slice(((value - 1) * 6), value * 6))
	};

	console.log(clients)
	return (
		<Box sx={{ mt: 4, mx: "auto", maxWidth: isMobile ? 360 : 1300 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
				<Typography variant="h5" sx={{ fontWeight: 600 }}>
					{texts.clientPage.client_list}
				</Typography>
				{/* <Tooltip title="Next">
					<IconButton
						onClick={() => setNextToOwners(true)}
						sx={{ bgcolor: "primary.main", color: "#fff" }}
					>
						<SkipNextIcon sx={{ fontSize: "28px" }} />
					</IconButton>
				</Tooltip> */}
			</Box>

			<TableContainer component={Paper} sx={{ borderRadius: 2, overflow: "auto" }}>
				<Table>
					<TableHead>
						<TableRow sx={{ bgcolor: theme.palette.primary.light }}>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.other}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.name}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.contact}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.comment}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.employee}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>Status</TableCell>
							<TableCell sx={{ fontWeight: "bold", color: "white" }}>
								{texts.clientPage.action}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{slicedClients.map((client: IClient, index: number) => (
							<React.Fragment key={client._id}>
								<TableRow sx={{ bgcolor: index % 2 === 0 ? "action.hover" : "background.paper" }}>
									<TableCell>
										<IconButton onClick={() => toggleRow(client._id)}>
											{expandedRows[client._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
										</IconButton>
									</TableCell>
									<TableCell>{client.name}</TableCell>
									<TableCell>
										<ul style={{ margin: 0, paddingLeft: "16px", listStyle: "none" }}>
											{client.contact.map((contact, idx) => (
												<li key={idx} style={{ fontSize: "14px", color: "#555" }}>
													📞 {contact}
												</li>
											))}
										</ul>
									</TableCell>
									<TableCell sx={{ fontSize: "14px", color: "#777" }}>{client.comment}</TableCell>
									<TableCell>
										{employees?.length > 1
											? employees?.find((e: any) => e._id === client.owner).name || ""
											: employees?._id === client.owner
											? employees?.name
											: ""}
									</TableCell>
									{client.clientStatus !== "none" ? (
										<TableCell>
											<svg
												fill={client.clientStatus}
												width="64px"
												height="64px"
												viewBox="0 0 256 256"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
												<g
													id="SVGRepo_tracerCarrier"
													stroke-linecap="round"
													stroke-linejoin="round"
												></g>
												<g id="SVGRepo_iconCarrier">
													{" "}
													<circle cx="127" cy="129" r="81" fill-rule="evenodd"></circle>{" "}
												</g>
											</svg>
										</TableCell>
									) : (
										<TableCell>
											<svg
												fill={theme.palette.primary.light}
												width="44px"
												height="44px"
												viewBox="0 0 256 256"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
												<g
													id="SVGRepo_tracerCarrier"
													stroke-linecap="round"
													stroke-linejoin="round"
												></g>
												<g id="SVGRepo_iconCarrier">
													{" "}
													<circle cx="127" cy="129" r="81" fill-rule="evenodd"></circle>{" "}
												</g>
											</svg>
										</TableCell>
									)}
									<TableCell>
										<Tooltip title="Edit">
											<IconButton onClick={() => handleEdit(client)} sx={{ color: "primary.main" }}>
												<EditIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title="Update status">
											<IconButton
												onClick={() => handleUpdate(client)}
												sx={{ color: "primary.main" }}
											>
												<UpgradeIcon />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>

								{/* Expandable Row with Details */}
								<TableRow>
									<TableCell colSpan={6} sx={{
										padding: 0,
										bgcolor: "background.default"
									}}>
										<Collapse in={expandedRows[client._id]} timeout="auto" unmountOnExit>
											<Box sx={{ padding: 2, borderTop: "1px solid #ddd" }}>
												<Typography
													variant="subtitle1"
													sx={{ fontWeight: "bold", marginBottom: "20px" }}
												>
													{texts.clientPage.clientPreferences}
												</Typography>
												<Box
													sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}
												>
													{client.action ? (
														<Typography variant="body2">
															📌 {texts.watch.action}:{" "}
															{client.action === "rent" ? texts.watch.rent : texts.watch.sell}
														</Typography>
													) : (
														""
													)}
													{client.type ? (
														<Typography variant="body2">
															🏠 {texts.watch.type}:{" "}
															{client.type === "apartment"
																? texts.watch.apartment || ""
																: client.type === "house"
																? texts.watch.house || ""
																: client.type === "commercial"
																? texts.watch.commercial
																: ""}
														</Typography>
													) : (
														""
													)}
													{client.status ? (
														<Typography variant="body2">
															📜 {texts.watch.status}:{" "}
															{client.status === "pre-owned"
																? texts.watch.preOwned
																: texts.watch.new}
														</Typography>
													) : (
														""
													)}
													{client.buildingType ? (
														<Typography variant="body2">
															🏢 {texts.watch.buildingType}:{" "}
															{client.buildingType === "Stone"
																? texts.watch.Stone || ""
																: client.buildingType === "Panels"
																? texts.watch.Panels || ""
																: client.buildingType === "Monolith"
																? texts.watch.Monolith || ""
																: client.buildingType === "Bricks"
																? texts.watch.Bricks || ""
																: client.buildingType === "Cassette"
																? texts.watch.Cassette || ""
																: client.buildingType === "Wooden"
																? texts.watch.Wooden
																: ""}
														</Typography>
													) : (
														""
													)}
													{client.surface ? (
														<Typography variant="body2">
															📏 {texts.watch.surface}: {client.surface.from} m² -{" "}
															{client.surface.to} m²
														</Typography>
													) : (
														""
													)}
													{client.ceiling ? (
														<Typography variant="body2">
															🏗️ {texts.watch.ceiling}: {client.ceiling.from} m -{" "}
															{client.ceiling.to} m
														</Typography>
													) : (
														""
													)}
													{client.rooms ? (
														<Typography variant="body2">
															🛏️ {texts.watch.rooms}: {client.rooms.from} - {client.rooms.to}
														</Typography>
													) : (
														""
													)}
													{client.bathrooms ? (
														<Typography variant="body2">
															🛁 {texts.watch.bathrooms}: {client.bathrooms.from} -{" "}
															{client.bathrooms.to}
														</Typography>
													) : (
														""
													)}
													{client.floor ? (
														<Typography variant="body2">
															📶 {texts.watch.floor}: {client.floor.from} - {client.floor.to}
														</Typography>
													) : (
														""
													)}
													{client.totalFloors ? (
														<Typography variant="body2">
															🏢 {texts.watch.totalFloors}: {client.totalFloors.from} -{" "}
															{client.totalFloors.to}
														</Typography>
													) : (
														""
													)}
													{client.renovation ? (
														<Typography variant="body2">
															🎨 {texts.watch.renovation}: {client.renovation.from} -{" "}
															{client.renovation.to}
														</Typography>
													) : (
														""
													)}
												</Box>
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Stack
				spacing={2}
				sx={{
					marginTop: "20px",
					marginBottom: "10px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Pagination
					size={isMobile ? "medium" : "large"}
					count={pageCount}
					page={page}
					onChange={handleChange}
					renderItem={(item) => (
						<PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
					)}
				/>
			</Stack>
		</Box>
	);
};

export default ClientList;
