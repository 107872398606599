import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useTheme,
	useMediaQuery,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MainContext } from "../../MainContext";
import { useForm, SubmitHandler } from "react-hook-form";
import Swal from "sweetalert2";
import { request } from "../../request";
import ClientPreferences from "./ClientPreferences";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

interface FormInputs {
	action: "rent" | "sell";
	type: "apartment" | "house" | "commercial";
	buildingType: "Stone" | "Panels" | "Monolith" | "Bricks" | "Cassette" | "Wooden";
	status: "pre-owned" | "new";
	surface: number;
	rooms: number;
	bathrooms: number;
	ceiling: number;
	renovation: number;
	floor: number;
	totalFloors: number;
}

interface IFormData {
	name: string;
	contact: string[];
	comment: string;
	owner: string;
}

const AddClient = () => {
	const [newType, setNewType] = useState("");
	const [values, setValues] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState("");
	const { updateClients, texts } = useContext(MainContext);
	const [formData, setFormData] = useState<IFormData>({
		name: "",
		contact: [],
		comment: "",
		owner: window.localStorage.getItem("token") ?? "",
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		getValues,
		control,
		watch,
	} = useForm<FormInputs>({
		defaultValues: {},
	});

	console.log(watch());

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		try {
			const preferences = new FormData();
			console.log(data);
			Object.entries(data).forEach(([key, value]) => {
				if (value) {
					if (typeof value === "object") {
						preferences.append(key, JSON.stringify(value));
					} else {
						preferences.append(key, value);
					}
				}
			});

			preferences.append("name", formData.name);
			preferences.append("contact", JSON.stringify(formData.contact)); // Convert array to string
			preferences.append("comment", formData.comment);
			preferences.append("owner", window.localStorage.getItem("token") ?? "");

			console.log(preferences);

			await request.post("/client", preferences, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			Swal.fire({
				title: "Client added successfully",
				icon: "success",
			});
			setFormData({
				name: "",
				contact: [],
				comment: "",
				owner: "",
			});
			updateClients();
		} catch (error) {
			console.error("Error creating client:", error);
			alert("Failed to create client.");
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && inputValue.trim()) {
			const updatedValues = [...values, inputValue.trim()];
			setValues(updatedValues);
			setFormData((prev) => ({ ...prev, contact: updatedValues }));
			setInputValue("");
		}
	};

	const handleDelete = (valueToDelete: string) => {
		const updatedValues = values.filter((value) => value !== valueToDelete);
		setValues(updatedValues);
		setFormData((prev) => ({ ...prev, contact: updatedValues }));
	};

	return (
		<>
			<Accordion sx={{
				maxWidth: isMobile ? 320 : 1300,
				mx: "auto",
				"&.Mui-expanded": {
					marginLeft: "auto",
					marginRight: "auto",
				},
				"&.Mui-expanded:first-of-type": {
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "30px",
				},
				"&:first-of-type": {
					marginTop: "30px",
				},
				"&:before": {
					display: "none",
				},
				"@media (max-width:600px)": {
					width: "100%",
				},
				borderRadius: "10px"
			}}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{color: "white"}}/>}
					aria-controls="panel1-content"
					id="panel1-header"
					sx={{
						bgcolor: theme.palette.primary.light,
						borderRadius: "10px"
					}}
					
				>
					<Typography component="span" sx={{color: 'white'}}>{texts.clientPage.add_client}</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{maxWidth: isMobile ? 320 : 1300, mx: "auto"}}>
					<Box
						component="form"
						sx={{
							maxWidth: isMobile ? 320 : 1300,
							mx: "auto",
							mt: 4,
							p: 4,
						}}
					>
						<Typography variant="h5" gutterBottom>
							{texts.clientPage.add_client}
						</Typography>
						<TextField
							label={texts.clientPage.name}
							name="name"
							value={formData.name}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
						/>
						<Box sx={{ marginTop: "10px" }}>
							<TextField
								label={texts.clientPage.contact}
								variant="outlined"
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={handleKeyDown}
								fullWidth
							/>
							<Box mt={2}>
								{values.map((value) => (
									<Chip
										key={value}
										label={value}
										onDelete={() => handleDelete(value)}
										style={{ margin: "5px" }}
									/>
								))}
							</Box>
						</Box>
						<TextField
							label={texts.clientPage.comment}
							name="comment"
							value={formData.comment}
							onChange={handleChange}
							fullWidth
							multiline
							margin="normal"
						/>
						<ClientPreferences control={control} setValue={setValue} watch={watch} />
						<Button
							type="button"
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							fullWidth
							sx={{ mt: 2 }}
						>
							{texts.clientPage.create_client}
						</Button>
					</Box>

				</AccordionDetails>
			</Accordion>
		</>
	);
};

export default AddClient;
