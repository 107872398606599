import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Divider, useMediaQuery, useTheme } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { MainContext } from "../../../MainContext";
import LocationInput from "../LocationInput";
import MainInput from "./MainInput";
import NumbersInputs from "./NumbersInput";
import PropertiesInput from "./PropertiesInput";
import DescriptionsInput from "./DescriptionsInput";
import UploadInput from "./UploadInput";
import OwnerInput from "./OwnerInput";
import BuyerInput from "./BuyerInput";
import EmployeeInput from "./EmployeeInput";
import { FormInputs } from "../../../interfaces/formInputs";
import { request } from "../../../request";


const PropertyInput: React.FC<{ full: boolean }> = ({ full }) => {
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [imagePreviews_doc, setImagePreviews_doc] = useState<string[]>([]);
    const [imageFiles_doc, setImageFiles_doc] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const {  
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<FormInputs>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { keys, newCode, texts, language, setErrorArr, errorArr } = useContext(MainContext);

    console.log(watch())
    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        try {
            setLoading(true);
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value) {
                    if (typeof value === "object") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
            });
            formData.append("filter_doc", JSON.stringify(imageFiles_doc.map((file) => file.name)));
            imageFiles.forEach((file) => formData.append("images", file));
            imageFiles_doc.forEach((file) => formData.append("images", file));
            await request.post("/property", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            localStorage.removeItem("watch");
            Swal.fire({
                title: texts.propertyInput.title,
                icon: "success",
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `${error}`,
            });
        } finally {
            setLoading(false);
        }
    };

    const onError = (errors: any) => {
        const errorList = Object.entries(errors).map(([field, _]) => field)
        setErrorArr(errorList)
        Swal.fire({
            icon: "error",
            title: texts.propertyInput.validationErrors,
            customClass: {
                container: "swal-error-container",
            },
        });

    };

    console.log(errorArr)
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const selectedFiles = Array.from(files);
            setImageFiles([...imageFiles, ...selectedFiles]);
            setImagePreviews([...imagePreviews, ...selectedFiles.map((file) => URL.createObjectURL(file))]);
        }
    };

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const selectedFiles = Array.from(files);
            setImageFiles_doc([...imageFiles_doc, ...selectedFiles]);
            setImagePreviews_doc([...imagePreviews_doc, ...selectedFiles.map((file) => file.name)]);
        }
    };

    const handleRemoveImage = (index: number) => {
        setImageFiles((prev) => prev.filter((_, i) => i !== index));
        setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const watched = watch();
        if (Object.keys(watched).length > 0) {
            localStorage.setItem("watch", JSON.stringify(watched));
        }
    }, [watch]);

    useEffect(() => {
        const savedValues = localStorage.getItem("watch");
        if (savedValues) {
            const parsedValues = JSON.parse(savedValues);
            reset(parsedValues);
        }
    }, []);

    return (
        <Box component="form" sx={{ p: 3 }} onSubmit={handleSubmit(onSubmit, onError)}>
            <MainInput control={control} setValue={setValue} />

            <Divider style={{ margin: "30px 0" }}>{texts.propertyInput.location}</Divider>
            <LocationInput {...register("location", { required: true })} />

            <NumbersInputs control={control} setValue={setValue} watch={watch} />

            <PropertiesInput register={register} control={control} />

            <DescriptionsInput register={register} control={control} />

            <UploadInput imagePreviews={imagePreviews} handleImageChange={handleImageChange} handleRemoveImage={handleRemoveImage} handleDocumentChange={handleDocumentChange} imagePreviews_doc={imagePreviews_doc} />

            <Box sx={{ display: isMobile ? "inline" : "flex" }}>
                <OwnerInput {...register("owner", { required: false })} watch={watch} setValue={setValue} />
                <BuyerInput {...register("buyer", { required: false })} setValue={setValue} watch={watch}/>
            </Box>

            <EmployeeInput {...register("agent", { required: true })} setValue={setValue} watch={watch}/>

            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading} sx={{ marginTop: "40px" }}>
                {loading ? <CircularProgress size={24} /> : texts.propertyInput.submitButton}
            </Button>
        </Box>
    );
};

export default PropertyInput;
