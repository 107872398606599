import React, { useState, useEffect, useContext } from "react";
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Typography,
	Autocomplete,
	InputAdornment,
	Chip,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { MainContext } from "../../MainContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { request } from "../../request";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Login = () => {
    const navigate = useNavigate()
	const [login, setLogin] = useState("");
	const [pass, setPass] = useState("");
	const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {updateToken} = useContext(MainContext)

	// Handle form submission
    const handleSubmit = async (e:any) => {
        e.preventDefault()
		try {
            const response = await request.post("/user/login", {
                login: login,
                pass: pass,
            });
            const token = response.data;
            localStorage.setItem("token", token);
            updateToken(token)
        
            if (token) {
                navigate("/");
            }
		} catch (error) {
			console.error("Error creating client:", error);
		}
	};

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/");
        }
    }, []);

	return (
		<>
				<Box
					component="form"
					sx={{
						maxWidth: isMobile ? 320 : 600,
						mx: "auto",
						mt: 4,
						p: 4,
						border: "1px solid #ccc",
						borderRadius: 2,
					}}
				>
					<Typography variant="h5" gutterBottom>
						Create User
					</Typography>
					<TextField label="Login" name="login" value={login} fullWidth required margin="normal" onChange={(e) => setLogin(e.target.value)}/>
					<TextField
						label="Password"
						name="password"
						value={pass}
						fullWidth
						multiline
						margin="normal"
                        onChange={(e) => setPass(e.target.value)}
					/>
					<Button type="button" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleSubmit}>
						Login
					</Button>
				</Box>
		</>
	);
};

export default Login;
