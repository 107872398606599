import { Box, FormLabel, Slider, Divider, useMediaQuery, useTheme} from "@mui/material";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { MainContext } from "../../../MainContext";

const FilterNumbersInput: React.FC<{ control: any }> = ({ control }) => {
	const { texts } = useContext(MainContext)
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box>
			<Divider style={{ margin: "30px 0" }}>Numbers</Divider>
			<Box sx={{ display: "inline", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						marginBottom: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
				>
					<FormLabel sx={{ fontWeight: 600, color: "#333" }}>{texts.watch.surface}</FormLabel>
					<Controller
						name="surface"
						control={control}
						defaultValue={{ from: 0, to: 200 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={0}
								max={200}
								step={1}
								marks={[
									{ value: 0, label: "0 m²" },
									{ value: 50, label: "50 m²" },
									{ value: 100, label: "100 m²" },
									{ value: 150, label: "150 m²" },
									{ value: 200, label: "200 m²" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#2196F3", // Change the slider color
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						marginBottom: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
				>
					{/* Number Input */}
					<FormLabel sx={{ fontWeight: 600, color: "#333" }} component="legend">
						{texts.watch.ceiling}
					</FormLabel>
					<Controller
						name="ceiling"
						control={control}
						defaultValue={{ from: 1, to: 5 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={1}
								max={5}
								step={0.05}
								marks={[
									{ value: 1, label: "1 m" },
									{ value: 2, label: "2 m" },
									{ value: 3, label: "3 m" },
									{ value: 4, label: "4 m" },
									{ value: 5, label: "5 m" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#4CAF50", // Change the slider color
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
			</Box>

			<Box sx={{ display: "inline", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						marginBottom: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
				>
					<FormLabel sx={{ fontWeight: 600, color: "#333" }}>{texts.watch.rooms}</FormLabel>
					<Controller
						name="rooms"
						control={control}
						defaultValue={{ from: 1, to: 10 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={1}
								max={4}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 2, label: "2" },
									{ value: 3, label: "3" },
									{ value: 4, label: "4+" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#FFC107", // Change the slider color
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						marginBottom: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
				>
					{/* Number Input */}
					<FormLabel sx={{ fontWeight: 600, color: "#333" }} component="legend">
						{texts.watch.bathrooms}
					</FormLabel>
					<Controller
						name="bathrooms"
						control={control}
						defaultValue={{ from: 1, to: 10 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={1}
								max={4}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 2, label: "2" },
									{ value: 3, label: "3" },
									{ value: 4, label: "4+" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#4CAF50", // Change the slider color
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
			</Box>

			<Box sx={{ display: "inline", marginTop: "30px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						marginBottom: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
					}}
				>
					<FormLabel sx={{ fontWeight: 600, color: "#333" }}>{texts.watch.floor}</FormLabel>
					<Controller
						name="floor"
						control={control}
						defaultValue={{ from: 1, to: 50 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={1}
								max={50}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 10, label: "10" },
									{ value: 20, label: "20" },
									{ value: 30, label: "30" },
									{ value: 40, label: "40" },
									{ value: 50, label: "50" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#FF5722", // Change the slider color to a bold hue
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: isMobile ? "90%" : 350,
						marginLeft: "20px",
						padding: "20px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						transition: "transform 0.3s ease-in-out",
						"&:hover": {
							transform: "translateY(-5px)",
						},
						mb: 5,
					}}
				>
					{/* Number Input */}
					<FormLabel sx={{ fontWeight: 600, color: "#333" }} component="legend">
						{texts.watch.totalFloors}
					</FormLabel>
					<Controller
						name="totalFloors"
						control={control}
						defaultValue={{ from: 1, to: 50 }}
						render={({ field: { onChange, value } }) => (
							<Slider
								value={[value.from, value.to]} // Use an array for range
								onChange={(_, newValue) => {
									//@ts-ignore
									const [from, to] = newValue;
									onChange({ from, to }); // Update form value
								}}
								valueLabelDisplay="auto"
								min={1}
								max={50}
								step={1}
								marks={[
									{ value: 1, label: "1" },
									{ value: 10, label: "10" },
									{ value: 20, label: "20" },
									{ value: 30, label: "30" },
									{ value: 40, label: "40" },
									{ value: 50, label: "50" },
								]}
								sx={{
									"& .MuiSlider-markLabel:nth-child(4)": {
										transform: "none", // Keep labels centered
									},
									"& .MuiSlider-root": {
										color: "#8BC34A", // Change the slider color to a fresh green
									},
									".css-14hitb9-MuiSlider-markLabel": {
										fontSize: isMobile ? '10px' : ""
									}
								}}
							/>
						)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default FilterNumbersInput;
